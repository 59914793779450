import React, { useRef, useEffect } from 'react';
import { ReactNode } from 'react';
import ForceGraph from 'force-graph';
import { useState } from 'react';
import * as d3 from 'd3';
import { SimulationNodeDatum } from 'd3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faMap,
  faInfoCircle,
  faLocationDot,
  faLink,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import NodeData from './data/NodeData';
import diamond from './icons/diamond.png';
import circle from './icons/circle.png';
import hexagon from './icons/hexagon.png';
import LoadingLogo from './icons/LoadingLogo';
import DefaultImage from './icons/DefaultImage';

import {
  NodeObject as ForceGraphNode,
  LinkObject as ForceGraphLink,
} from 'force-graph';
import ImageMap from './helpers/ImageMap';

type GraphData = {
  nodes: GraphNode[];
  links: GraphLink[];
};

export interface GraphNode extends ForceGraphNode {
  id: number;
  // dataId: number;
  collapsed: boolean;
  childLinks: GraphLink[];
  parentLinks: GraphLink[];
  // neighbors?: GraphNode[];
  // links?: GraphLink[];
  type: 'organisation' | 'project' | 'person' | 'label';
  isLabel?: boolean;
  // isFixed: boolean;
  // isRoot?: boolean;
}

export interface GraphLink extends ForceGraphLink {
  role?: string;
}

export type Link = {
  url: string;
  type: 'instagram' | 'twitter' | 'website' | 'linkedin' | 'github' | 'report';
};

const linkIconMap: Record<string, any> = {
  instagram: faLink,
  twitter: faLink,
  website: faLink,
  linkedin: faLink,
  github: faLink,
  report: faFilePdf,
};

const { nodeDetails, nodes, links } = NodeData;

const gData: GraphData = {
  nodes: nodes,
  links: links,
};

const imageMap = ImageMap;

// Aggregate link data to calculate the number of links between each pair of nodes
const aggregatedLinks = gData.links.reduce(
  (acc, link) => {
    const key = `${link.source}-${link.target}`;
    if (!acc[key]) {
      acc[key] = { ...link, count: 1 }; // Initialize count to 1 for the first occurrence
    } else {
      acc[key].count! += 1; // Increment count for subsequent occurrences
    }
    return acc;
  },
  {} as Record<string, GraphLink & { count: number }>
);

gData.links = Object.values(aggregatedLinks);

// Link parent/children
const nodesById = Object.fromEntries(nodes.map((node) => [node.id, node]));

gData.links.forEach((link) => {
  const sourceNode = nodesById[link.source as number];
  const targetNode = nodesById[link.target as number];

  if (sourceNode && targetNode) {
    sourceNode.childLinks!.push(link);
    targetNode.parentLinks = targetNode.parentLinks || [];
    targetNode.parentLinks!.push(link);
  } else {
    console.warn(`Warning: Link source or target not found for link: ${link}`); // Log a warning if nodes are not found
  }
});

const getPrunedTree = (startNode: GraphNode | null = null) => {
  const visibleNodes: GraphNode[] = [];
  const visibleLinks: GraphLink[] = [];
  const visitedNodes = new Set<number>();

  const traverseTree = (node: GraphNode) => {
    if (visitedNodes.has(node.id)) return; // Base case: if node is already visited, return
    visitedNodes.add(node.id); // Mark node as visited
    visibleNodes.push(node); // Add node to visible nodes

    if (node.collapsed) {
      return; // If node is collapsed, stop traversal
    }

    const connectedLinks = [
      ...(node.childLinks || []),
      ...(node.parentLinks || []),
    ];

    connectedLinks.forEach((link) => {
      const targetNode =
        typeof link.target === 'object'
          ? (link.target as GraphNode)
          : nodesById[link.target as number];
      const sourceNode =
        typeof link.source === 'object'
          ? (link.source as GraphNode)
          : nodesById[link.source as number];

      visibleLinks.push(link); // Add link to visible links

      // Traverse the connected node that is not the current node
      if (sourceNode.id === node.id) {
        traverseTree(targetNode); // Recursively traverse target node
      } else if (targetNode.id === node.id) {
        traverseTree(sourceNode); // Recursively traverse source node
      }
    });
  };

  if (startNode) {
    traverseTree(startNode); // Start from the root node
  } else {
    gData.links.forEach((link) => {
      visibleLinks.push(link);
    });
    gData.nodes.forEach((node) => {
      visibleNodes.push(node);
    });
  }

  return { nodes: visibleNodes, links: visibleLinks };
};

const imageCache: Record<number, HTMLImageElement> = {};

const preloadImages = (
  imageMap: Record<number, string>,
  onLoad: () => void
) => {
  const totalImages = Object.keys(imageMap).length;
  let loadedImages = 0;

  Object.entries(imageMap).forEach(([id, src]) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        onLoad();
      }
    };
    imageCache[parseInt(id)] = img;
  });
};

// // Cross-link node objects
// gData.links.forEach((link) => {
//   const a = gData.nodes.find((node) => node.id === link.source) as GraphNode;
//   const b = gData.nodes.find((node) => node.id === link.target) as GraphNode;
//   if (a && b) {
//     a.neighbors = a.neighbors || [];
//     b.neighbors = b.neighbors || [];
//     a.neighbors.push(b);
//     b.neighbors.push(a);

//     a.links = a.links || [];
//     b.links = b.links || [];
//     a.links.push(link);
//     b.links.push(link);
//   }
// });

// Debugging: Log the aggregated links to check the structure
// console.log('Aggregated Links:', gData.links);

const SimpleGraph: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const graphRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState(true);

  const [rootNode, setRootNode] = useState<GraphNode | null>(null); // State for root node

  useEffect(() => {
    preloadImages(imageMap, () => {
      setIsLoading(false);
    });
    console.log('isLoading', isLoading);
  }, []);

  const selectedNodesRef = useRef<Set<GraphNode>>(new Set());
  const selectedLinksRef = useRef<Set<GraphLink>>(new Set());
  const [highlightNodes, setHighlightNodes] = useState<Set<GraphNode>>(
    new Set()
  );
  const [highlightLinks, setHighlightLinks] = useState<Set<GraphLink>>(
    new Set()
  );
  const [hoverNode, setHoverNode] = useState<GraphNode | null>(null);
  const [allCollapsed, setAllCollapsed] = useState<boolean>(false);
  const [currentMenu, setCurrentMenu] = useState<HTMLDivElement | null>(null);
  const [collapseEnabled, setCollapseEnabled] = useState<boolean>(false); // State to manage collapse functionality
  const [showLabels, setShowLabels] = useState<boolean>(false); // State to manage label visibility
  const [showLinkLabels, setShowLinkLabels] = useState<boolean>(true); // State to manage link label visibility
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(true); // State to manage sidebar visibility
  const [prevRootNode, setPrevRootNode] = useState<GraphNode | null>(null);
  const [prevXCoord, setPrevXCoord] = useState<number | null>(null);
  const [prevYCoord, setPrevYCoord] = useState<number | null>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<'map' | 'smallzoom' | 'largezoom'>(
    'map'
  );
  const [currentZoom, setCurrentZoom] = useState<number>(1); // Initialize with default zoom level

  const toggleCollapseFunctionality = () => {
    setCollapseEnabled((prev) => !prev);
  };

  const toggleLabelVisibility = () => {
    console.log('toggleLabelVisibility', showLabels);
    setShowLabels((prev) => !prev);
  };

  const toggleLinkLabelVisibility = () => {
    setShowLinkLabels((prev) => !prev);
  };
  function createContextMenu(
    customNode: GraphNode,
    event: MouseEvent
  ): HTMLDivElement {
    const menu = document.createElement('div');
    menu.style.position = 'absolute';
    menu.style.left = `${event.clientX + 15}px`;
    menu.style.top = `${event.clientY}px`;
    menu.style.backgroundColor = 'white';
    menu.style.border = '1px solid #ccc';
    menu.style.borderRadius = '8px';
    menu.style.padding = '10px';
    menu.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    menu.style.transform = 'translateY(-5px)';
    menu.style.zIndex = '1000';

    // Create the inner content with buttons
    const content = document.createElement('div');
    content.style.display = 'flex';
    content.style.justifyContent = 'space-between';
    content.style.alignItems = 'center';

    const info = document.createElement('div');
    const option1Button = document.createElement('button');
    option1Button.id = 'option1';
    option1Button.textContent = 'Center/Zoom on Node';
    option1Button.style.display = 'block';
    option1Button.style.margin = '5px 0';

    const option2Button = document.createElement('button');
    option2Button.id = 'option2';
    option2Button.textContent = 'Set as Root Node';
    option2Button.style.display = 'block';
    option2Button.style.margin = '5px 0';

    const option3Button = document.createElement('button');
    option3Button.id = 'option3';
    option3Button.textContent = 'Explore from Node';
    option3Button.style.display = 'block';
    option3Button.style.margin = '5px 0';

    info.appendChild(option1Button);
    info.appendChild(option2Button);
    info.appendChild(option3Button);

    const closeButton = document.createElement('button');
    closeButton.id = 'close-menu';
    closeButton.textContent = '×';
    closeButton.style.background = 'none';
    closeButton.style.border = 'none';
    closeButton.style.cursor = 'pointer';

    content.appendChild(info);
    // content.appendChild(closeButton);

    menu.appendChild(content);
    // Attach event listener to the button
    option1Button.addEventListener('click', () => {
      if (
        graphRef.current &&
        customNode.x !== undefined &&
        customNode.y !== undefined
      ) {
        graphRef.current.centerAt(customNode.x, customNode.y, 1000);
        graphRef.current.zoom(4, 2000);
      } else {
        console.error('Graph object or customNode coordinates are not defined'); // Debug: Log if Graph or coordinates are not available
      }
      closeMenu(menu); // Close the menu after the action
    });

    option2Button.addEventListener('click', () => {
      setRootNode(customNode);
      setPrevRootNode(rootNode);
      if (customNode.x !== undefined && customNode.y !== undefined) {
        graphRef.current.zoom(10);
        graphRef.current.centerAt(customNode.x - 5000, customNode.y, 1000);
      }

      // Collapse all nodes except the selected one
      nodes.forEach((node) => {
        node.collapsed = node.id !== customNode.id;
      });

      graphRef.current.graphData(getPrunedTree(customNode)); // Update the graph data with the new root node
      closeMenu(menu); // Close the menu after the action
    });

    option3Button.addEventListener('click', () => {
      setRootNode(customNode);
      setPrevRootNode(rootNode);

      // Collapse all nodes except the selected one
      nodes.forEach((node) => {
        node.collapsed = node.id !== customNode.id;
      });

      graphRef.current.graphData(getPrunedTree(customNode)); // Update the graph data with the new root node
      closeMenu(menu); // Close the menu after the action
    });

    return menu;
  }

  function closeMenu(menu: HTMLDivElement) {
    document.body.removeChild(menu);
    setCurrentMenu(null);
  }

  function handleClickOutside(event: MouseEvent, menu: HTMLDivElement) {
    if (menu && !menu.contains(event.target as Node)) {
      closeMenu(menu);
    }
  }
  useEffect(() => {
    if (!containerRef.current || isLoading) return;
    console.log('isLoading', isLoading);
    if (prevRootNode !== rootNode && prevRootNode) {
      prevRootNode.fx = undefined;
      prevRootNode.fy = undefined;
    }

    // // Create label nodes
    // const labelNodes = gData.nodes.map((node) => ({
    //   id: `label-${node.id}`,
    //   x: node.x,
    //   y: node.y,
    //   fx: node.x,
    //   fy: node.y,
    //   isLabel: true,
    //   nodeId: node.id,
    // }));

    // const allNodes = [...gData.nodes, ...labelNodes];
    // console.log('allNodes', allNodes);

    const linkDistance = (link: ForceGraphLink, index: number) => {
      // Alternate distances: 100 for closer, 200 for further
      return index % 2 === 0 ? 100 : 200;
    };

    const Graph = ForceGraph()(containerRef.current)
      .graphData({ nodes: nodes, links: links })
      .nodeId('id')
      // .nodeLabel('id')
      .nodeAutoColorBy('group')
      .onNodeHover(
        showLabels && rootNode
          ? () => {
              console.log('showLabels', showLabels);
              console.log('rootNode', rootNode);
            } // Disable hover functionality when showLabels is true
          : (
              node: ForceGraphNode | null,
              previousNode: ForceGraphNode | null
            ) => {
              highlightNodes.clear();
              highlightLinks.clear();
              if (node) {
                const customNode = node as GraphNode;
                highlightNodes.add(customNode);
                setHoverNode(node ? (node as GraphNode) : null);
              }
            }
      )
      .onNodeClick((node: ForceGraphNode | null, event: MouseEvent) => {
        if (node) {
          if (node.id === rootNode?.id) {
            return;
          }
          const customNode = node as GraphNode;

          setHighlightNodes(new Set([]));
          setHighlightLinks(new Set([]));
          // Collapse all nodes except the selected one
          nodes.forEach((node) => {
            node.collapsed = node.id !== customNode.id;
          });
          // Set the clicked node as the root node
          const setAsRootNode = (node: GraphNode) => {
            // Clear previous root node
            // if (rootNode) {
            //   rootNode.isRoot = false;
            // }

            // Set the clicked node as the new root node
            // node.isRoot = true;
            setPrevXCoord(customNode.x ?? 0);
            setPrevYCoord(customNode.y ?? 0);
            setRootNode(node);
            setPrevRootNode(rootNode);
          };

          setAsRootNode(customNode);

          graphRef.current.graphData(getPrunedTree(customNode)); // Update the graph data with the new root node
        }

        //   // Single select functionality
        //   if (event.ctrlKey || event.shiftKey || event.altKey) {
        //     selectedNodesRef.current.has(node as GraphNode)
        //       ? selectedNodesRef.current.delete(node as GraphNode)
        //       : selectedNodesRef.current.add(node as GraphNode);
        //   } else {
        //     if (selectedNodesRef.current.has(node as GraphNode)) {
        //       selectedNodesRef.current.delete(node as GraphNode);
        //     } else {
        //       selectedNodesRef.current.clear();
        //       selectedNodesRef.current.add(node as GraphNode);
        //     }
        //   }
        // }
        // // Update node color based on selection
        // Graph.nodeColor((node) =>
        //   selectedNodesRef.current.has(node as GraphNode) ? 'blue' : ''
        // );

        // // Trigger a re-render by updating the state
        // setHighlightNodes(new Set(selectedNodesRef.current)); // Update highlight nodes
      })
      .onNodeRightClick((node: ForceGraphNode | null, event: MouseEvent) => {
        // if (node) {
        //   const customNode = node as GraphNode;
        //   // Remove any existing menu
        //   if (currentMenu) {
        //     document.body.removeChild(currentMenu);
        //     setCurrentMenu(null);
        //   }
        //   const menu = createContextMenu(customNode, event);
        //   document.body.appendChild(menu);
        //   setCurrentMenu(menu);
        //   const closeButton = document.getElementById('close-menu');
        //   closeButton?.addEventListener('click', () => closeMenu(menu));
        //   document.addEventListener(
        //     'click',
        //     (event) => handleClickOutside(event, menu),
        //     { once: true }
        //   );
        // }
      })
      .onLinkHover(
        (link: ForceGraphLink | null, previousLink: ForceGraphLink | null) => {
          highlightLinks.clear(); // Clear previous highlights
          if (link) {
            highlightLinks.add(link as GraphLink); // Add the hovered link to the highlight set
          }
        }
      )
      .nodeCanvasObjectMode((node) => 'replace')
      .autoPauseRedraw(false)
      // .onZoom((zoomLevel) => {
      //   setCurrentZoom(zoomLevel); // Update the current zoom level
      // })
      .nodeCanvasObject(
        (
          node: ForceGraphNode,
          ctx: CanvasRenderingContext2D,
          globalScale: number
        ) => {
          const customNode = node as GraphNode;

          const NODE_R = node.id === rootNode?.id ? 8 : 6;

          // Draw the grey outline
          ctx.beginPath();
          if (customNode.type === 'organisation') {
            ctx.moveTo((customNode.x ?? 0) - NODE_R, customNode.y ?? 0);
            ctx.lineTo(customNode.x ?? 0, (customNode.y ?? 0) - NODE_R);
            ctx.lineTo((customNode.x ?? 0) + NODE_R, customNode.y ?? 0);
            ctx.lineTo(customNode.x ?? 0, (customNode.y ?? 0) + NODE_R);
            ctx.closePath();
          } else if (customNode.type === 'project') {
            const angle = (Math.PI * 2) / 6;
            ctx.moveTo(
              (customNode.x ?? 0) + NODE_R * Math.cos(0),
              (customNode.y ?? 0) + NODE_R * Math.sin(0)
            );
            for (let i = 1; i <= 6; i++) {
              ctx.lineTo(
                (customNode.x ?? 0) + NODE_R * Math.cos(i * angle),
                (customNode.y ?? 0) + NODE_R * Math.sin(i * angle)
              );
            }
          } else {
            ctx.arc(
              customNode.x ?? 0,
              customNode.y ?? 0,
              NODE_R + 2, // Slightly larger radius for the outline
              0,
              2 * Math.PI,
              false
            );
          }
          // ctx.fillStyle =
          //   customNode.type === 'organisation'
          //     ? 'rgba(100, 150, 200, 0.2)' // Light blue for organisations
          //     : customNode.type === 'project'
          //       ? 'rgba(100, 200, 100, 0.2)' // Light green for projects
          //       : 'rgba(200, 100, 150, 0.2)'; // Light pink for persons
          // ctx.fill();

          // // Draw the hover effect
          // if (highlightNodes.has(node as GraphNode)) {
          //   ctx.beginPath();
          //   if (customNode.type === 'organisation') {
          //     ctx.moveTo((customNode.x ?? 0) - NODE_R, customNode.y ?? 0);
          //     ctx.lineTo(customNode.x ?? 0, (customNode.y ?? 0) - NODE_R);
          //     ctx.lineTo((customNode.x ?? 0) + NODE_R, customNode.y ?? 0);
          //     ctx.lineTo(customNode.x ?? 0, (customNode.y ?? 0) + NODE_R);
          //     ctx.closePath();
          //   } else if (customNode.type === 'project') {
          //     const angle = (Math.PI * 2) / 6;
          //     ctx.moveTo(
          //       (customNode.x ?? 0) + NODE_R * Math.cos(0),
          //       (customNode.y ?? 0) + NODE_R * Math.sin(0)
          //     );
          //     for (let i = 1; i <= 6; i++) {
          //       ctx.lineTo(
          //         (customNode.x ?? 0) + NODE_R * Math.cos(i * angle),
          //         (customNode.y ?? 0) + NODE_R * Math.sin(i * angle)
          //       );
          //     }
          //   } else {
          //     ctx.arc(
          //       customNode.x ?? 0,
          //       customNode.y ?? 0,
          //       NODE_R + 4, // Slightly larger radius for the hover effect
          //       0,
          //       2 * Math.PI,
          //       false
          //     );
          //   }
          //   ctx.fillStyle = 'white'; // Change hover color to white
          //   ctx.fill();
          // }

          // Draw the node
          ctx.beginPath();
          const x = customNode.x ?? 0;
          const y = customNode.y ?? 0;

          if (customNode.type === 'organisation') {
            ctx.moveTo(x - NODE_R, y);
            ctx.lineTo(x, y - NODE_R);
            ctx.lineTo(x + NODE_R, y);
            ctx.lineTo(x, y + NODE_R);
            ctx.closePath();
          } else if (customNode.type === 'project') {
            const angle = (Math.PI * 2) / 6;
            ctx.moveTo(x + NODE_R * Math.cos(0), y + NODE_R * Math.sin(0));
            for (let i = 1; i <= 6; i++) {
              ctx.lineTo(
                x + NODE_R * Math.cos(i * angle),
                y + NODE_R * Math.sin(i * angle)
              );
            }
          } else {
            ctx.arc(x, y, NODE_R, 0, 2 * Math.PI, false);
          }

          const img = imageCache[customNode.id];
          if (img) {
            ctx.save();
            ctx.clip();
            ctx.drawImage(img, x - NODE_R, y - NODE_R, NODE_R * 2, NODE_R * 2);
            ctx.restore();
          } else {
            ctx.fillStyle =
              customNode.type === 'organisation'
                ? '#FFC947' // Yellow for organisations
                : customNode.type === 'project'
                  ? '#6DD055' // Green for projects
                  : '#47B2FF'; // Blue for persons
            ctx.fill();
          }

          // Draw the border depending on the color
          ctx.strokeStyle =
            customNode.type === 'organisation'
              ? '#FFC947' // Yellow for organisations
              : customNode.type === 'project'
                ? '#6DD055' // Green for projects
                : '#47B2FF'; // Blue for persons
          ctx.lineWidth = 0.5;
          ctx.stroke();

          const label = nodeDetails[customNode.id]?.name || 'Unknown'; // Get the node ID as a label, default to 'Unknown' if undefined
          const fontSize = 12 / globalScale;

          // Draw the label text only if the node is hovered
          if (
            highlightNodes.has(customNode) &&
            customNode.id !== rootNode?.id
          ) {
            const customX = customNode.x ?? 0;
            const customY = customNode.y ?? 0 - NODE_R * 2;
            ctx.font = `${fontSize}px Sans-Serif`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle'; // Ensure vertical alignment
            const padding = 1; // Increased padding around the text
            const maxWidth =
              viewMode === 'largezoom'
                ? 5
                : viewMode === 'smallzoom'
                  ? 50
                  : 100; // Maximum width for the label
            const words = label.split(' ');
            let line = '';
            const lines = [];
            for (let n = 0; n < words.length; n++) {
              const testLine = line + words[n] + ' ';
              const testWidth = ctx.measureText(testLine).width;
              if (testWidth > maxWidth && n > 0) {
                lines.push(line);
                line = words[n] + ' ';
              } else {
                line = testLine;
              }
            }
            lines.push(line);

            const textHeight = fontSize * lines.length;
            const textWidth = Math.max(
              ...lines.map((line) => ctx.measureText(line).width)
            );

            const backgroundColor = 'rgba(255, 255, 255, 0.7)'; // White background with 70% transparency

            ctx.fillStyle = backgroundColor; // Set background color with transparency
            ctx.beginPath();
            ctx.roundRect(
              customX - textWidth / 2,
              customY - textHeight / 2,
              textWidth, // Increased padding to make the rectangle wider
              textHeight,
              10 // Radius for rounded corners
            ); // Draw the background rectangle with rounded edges
            ctx.fill(); // Fill the background rectangle

            ctx.fillStyle = 'black'; // Text color
            lines.forEach((line, index) => {
              ctx.fillText(
                line,
                customX,
                customY - textHeight / 2 + fontSize * (index + 0.5) // Offset the label lower based on NODE_R
              );
            });
          }
          // } else {
          //   const customX = customNode.x ?? 0;
          //   const customY = (customNode.y ?? 0) + NODE_R + zoom * 1.5;
          //   ctx.font = `${fontSize}px Sans-Serif`;
          //   ctx.textAlign = 'center';
          //   ctx.textBaseline = 'middle'; // Ensure vertical alignment
          //   const padding = 1; // Increased padding around the text
          //   const maxWidth = 50 * globalScale; // Maximum width for the label
          //   console.log(maxWidth);
          //   const words = label.split(' ');
          //   let line = '';
          //   const lines = [];
          //   for (let n = 0; n < words.length; n++) {
          //     const testLine = line + words[n] + ' ';
          //     const testWidth = ctx.measureText(testLine).width;
          //     if (testWidth > maxWidth && n > 0) {
          //       lines.push(line);
          //       line = words[n] + ' ';
          //     } else {
          //       line = testLine;
          //     }
          //   }
          //   lines.push(line);

          //   const textHeight = fontSize * lines.length;
          //   const textWidth = Math.max(
          //     ...lines.map((line) => ctx.measureText(line).width)
          //   );

          //   const backgroundColor = 'rgba(255, 255, 255, 0.7)'; // White background with 70% transparency

          //   ctx.fillStyle = backgroundColor; // Set background color with transparency
          //   ctx.beginPath();
          //   ctx.roundRect(
          //     customX - textWidth / 2,
          //     customY - textHeight / 2,
          //     textWidth, // Increased padding to make the rectangle wider
          //     textHeight,
          //     10 // Radius for rounded corners
          //   ); // Draw the background rectangle with rounded edges
          //   ctx.fill(); // Fill the background rectangle

          //   ctx.fillStyle = 'black'; // Text color
          //   lines.forEach((line, index) => {
          //     ctx.fillText(
          //       line,
          //       customX,
          //       customY - textHeight / 2 + fontSize * (index + 0.5) // Offset the label lower based on NODE_R
          //     );
          //   });
          // }
        }
      )
      // .nodeCanvasObjectMode(() => 'after')
      // .nodeCanvasObject(
      //   (
      //     node: ForceGraphNode,
      //     ctx: CanvasRenderingContext2D,
      //     globalScale: number
      //   ) => {
      //     const customNode = node as GraphNode;
      //     const label = nodeDetails[customNode.id]?.name || 'Unknown'; // Get the node ID as a label, default to 'Unknown' if undefined
      //     const fontSize = 12 / globalScale;
      //     const NODE_R = node.id === rootNode?.id ? 8 : 6;
      //     // Drawing labels
      //     if (!rootNode) {
      //       // Draw the label text only if the node is hovered
      //       if (highlightNodes.has(customNode)) {
      //         const customX = customNode.x ?? 0;
      //         const customY = customNode.y ?? 0 - 1.5;
      //         ctx.font = `${fontSize}px Sans-Serif`;
      //         ctx.textAlign = 'center';
      //         ctx.textBaseline = 'middle'; // Ensure vertical alignment
      //         // This line already aligns the text in the middle
      //         const padding = 1; // Increased padding around the text
      //         const textWidth = ctx.measureText(label).width;
      //         // const backgroundColor =
      //         //   customNode.type === 'organisation'
      //         //     ? 'rgba(255, 201, 71, 0.4)' // Yellow for organisations with 70% transparency
      //         //     : customNode.type === 'project'
      //         //       ? 'rgba(109, 208, 85, 0.4)' // Green for projects with 70% transparency
      //         //       : 'rgba(71, 178, 255, 0.4)'; // Blue for persons with 70% transparency

      //         const backgroundColor = 'rgba(255, 255, 255, 0.7)'; // White background with 70% transparency

      //         ctx.fillStyle = backgroundColor; // Set background color with transparency
      //         ctx.beginPath();
      //         ctx.roundRect(
      //           // customX - textWidth / 2 - padding * 2,
      //           // customY - fontSize / 2 - padding * 2,
      //           customX - textWidth / 2,
      //           customY - fontSize / 2,
      //           textWidth, // Increased padding to make the rectangle wider
      //           fontSize,
      //           10 // Radius for rounded corners
      //         ); // Draw the background rectangle with rounded edges
      //         ctx.fill(); // Fill the background rectangle

      //         ctx.fillStyle = 'black'; // Text color
      //         ctx.fillText(
      //           label,
      //           customX,
      //           customY // Offset the label lower based on NODE_R
      //         );
      //       }
      //     } else {
      //       const customX = customNode.x ?? 0;
      //       const customY = (customNode.y ?? 0) + NODE_R + zoom * 1.5;
      //       ctx.font = `${fontSize}px Sans-Serif`;
      //       ctx.textAlign = 'center';
      //       ctx.textBaseline = 'middle';
      //       // ctx.fillStyle = 'white'; // Background color for the label
      //       // const textWidth = ctx.measureText(label).width;
      //       // const padding = 1; // Padding around the text
      //       // ctx.fillRect(
      //       //   customX - textWidth / 2 - padding,
      //       //   customY - fontSize / 2 - padding,
      //       //   textWidth + padding * 1.2,
      //       //   fontSize + padding * 1.2
      //       // ); // Draw the background rectangle
      //       ctx.fillStyle = 'black'; // Text color
      //       ctx.fillText(
      //         label,
      //         customX,
      //         customY // Offset the label lower based on NODE_R
      //       );
      //     }
      //   }
      // )
      .linkCanvasObject((link: GraphLink, ctx: CanvasRenderingContext2D) => {
        const start = link.source;
        const end = link.target;

        const startNode = start as GraphNode; // Cast to GraphNode
        const endNode = end as GraphNode; // Cast to GraphNode

        // Ignore unbound links
        if (typeof start !== 'object' || typeof end !== 'object') return;

        // // Set dashed style for links between people and projects
        // if (
        //   (startNode.type === 'person' && endNode.type === 'project') ||
        //   (startNode.type === 'project' && endNode.type === 'person')
        // ) {
        //   ctx.setLineDash([3, 3]); // Set dash pattern with more frequent dashes
        //   // ctx.lineWidth = highlightLinks.has(link) ? 2 : 0.5; // Set dashed line thickness to 0.5 if highlighted
        // } else {
        //   ctx.setLineDash([]); // Solid line for other links
        // }

        // Draw the link
        ctx.beginPath();
        ctx.moveTo(start.x ?? 0, start.y ?? 0);
        ctx.lineTo(end.x ?? 0, end.y ?? 0);
        ctx.strokeStyle = '#D8D8D8'; // Set link color
        ctx.lineWidth = highlightLinks.has(link) ? 2 : 0.5; // Set link width thicker if highlighted
        ctx.stroke();
      });
    // .d3Force('link', d3.forceLink().distance(100).strength(1)); // Increased link distance and reduced strength
    //   .d3Force('charge', d3.forceManyBody().strength(-100)) // Increased repulsion strength
    //   .d3Force('collide', d3.forceCollide().radius(10))

    // // .d3Force('radial', d3.forceRadial(280, 0, 0).strength(0.5))

    // if (rootNode) {
    //   // Only add this force if there is a root node selected
    //   graphRef.current.d3Force('bounding-circle', () => {
    //     const radius = 320; // Define the radius of the bounding circle
    //     gData.nodes.forEach((node) => {
    //       const distance = Math.sqrt((node.x ?? 0) ** 2 + (node.y ?? 0) ** 2);
    //       if (distance > radius) {
    //         const angle = Math.atan2(node.y ?? 0, node.x ?? 0);
    //         node.x = radius * Math.cos(angle);
    //         node.y = radius * Math.sin(angle);
    //       }
    //     });
    //   });
    // }

    graphRef.current = Graph;
    graphRef.current.zoom(false);
    // Inside the useEffect for rootNode changes
    if (rootNode) {
      console.log('Root node found:', rootNode);
      const childLinks = rootNode.childLinks || [];
      console.log('Child links:', childLinks);

      if (childLinks.length > 9) {
        console.log('More than 9 child links detected, applying force link.');
        graphRef.current.d3Force(
          'link',
          d3
            .forceLink()
            .distance(
              (link: d3.SimulationLinkDatum<d3.SimulationNodeDatum>) => {
                const childIndex = childLinks.findIndex(
                  (childLink) => childLink === link
                ); // Get the index in childLinks
                // const childNode = gData.nodes.find(
                //   (node) => node.id === link.target
                // ); // Assuming link.target gives the child node ID

                const targetNode = link.target as GraphNode; // Cast to GraphNode

                const labelLength = targetNode
                  ? nodeDetails[targetNode.id].name.length
                  : 0; // Get the length of the child node's name

                console.log(
                  `Link target: ${targetNode.id}, Label length: ${labelLength}, Child index: ${childIndex}`
                );

                return labelLength > 50 ? 100 : childIndex % 2 === 0 ? 50 : 100; // Force to outer if name length > 50
              }
            )
            .strength(1)
        ); // Apply force only in root node view
      } else {
        console.log('9 or fewer child links, no force link applied.');
      }

      graphRef.current.d3ReheatSimulation(); // Reapply forces
    } else {
      // No changes applied
    }

    // // Log original nodes to check their x and y values
    // console.log('Original nodes:', gData.nodes);

    // // Create label nodes with x and y values from original nodes
    // const newLabelNodes = gData.nodes.map((node) => {
    //   const x = node.x ?? 0; // Default to 0 if undefined
    //   const y = node.y ?? 0; // Default to 0 if undefined
    //   console.log(node.x);
    //   console.log(`Creating label for node ${node.id}: x=${x}, y=${y}`); // Log x and y values
    //   return {
    //     id: node.id * 100,
    //     x: x,
    //     y: y,
    //     fx: x,
    //     fy: y,
    //     isLabel: true,
    //     nodeId: node.id,
    //   };
    // });
    // // setLabelNodes(newLabelNodes); // Update the state with new label nodes

    // // Update the graph data with label nodes
    // graphRef.current.graphData({
    //   nodes: [...gData.nodes, ...newLabelNodes],
    //   links: gData.links,
    // });

    // console.log('graphRef.current.graphData', graphRef.current.graphData);
    // console.log('Graph data loaded!');

    // Set the default zoom level when the graph is first loaded
    if (rootNode && rootNode.childLinks.length > 9) {
      console.log(
        'Setting zoom to 2.5 for rootNode with more than 9 child links'
      );
      graphRef.current.zoom(2.5, 400); // Set your desired default zoom level here
      graphRef.current.centerAt(110, 10);
      setZoom(2.5);
      setViewMode('smallzoom');
    } else if (rootNode && rootNode.childLinks.length <= 9) {
      console.log(
        'Setting zoom to 5.5 for rootNode with 9 or fewer child links'
      );
      graphRef.current.zoom(5.5, 400); // Set your desired default zoom level here
      setZoom(5.5);
      setViewMode('largezoom');
      graphRef.current.centerAt(60, 10);
    } else {
      console.log('Setting zoom to 2 for no rootNode or no child links');
      graphRef.current.zoom(2, 800); // Set your desired default zoom level here
      setZoom(1.5);
      setViewMode('map');
      graphRef.current.centerAt(0, 0);
    }

    // Update the graph when rootNode changes
    graphRef.current.graphData(getPrunedTree(rootNode));

    // Release other nodes
    gData.nodes.forEach((node) => {
      if (rootNode && node.id !== rootNode.id) {
        node.fx = undefined;
        node.fy = undefined;
      }
    });

    // Re-apply forces
    graphRef.current.d3ReheatSimulation();

    // graphRef.current.nodeCanvasObject(
    //   (node: GraphNode, ctx: CanvasRenderingContext2D, globalScale: number) => {
    //     const customNode = node as GraphNode;
    //     const label = nodeDetails[customNode.id]?.name || 'Unknown'; // Get the node ID as a label, default to 'Unknown' if undefined
    //     const fontSize = 12 / globalScale;
    //     const NODE_R = node.id === rootNode?.id ? 8 : 6;
    //     // Drawing labels
    //     if (!rootNode) {
    //       // Draw the label text only if the node is hovered
    //       if (highlightNodes.has(customNode)) {
    //         const customX = customNode.x ?? 0;
    //         const customY = customNode.y ?? 0 - 1.5;
    //         ctx.font = `${fontSize}px Sans-Serif`;
    //         ctx.textAlign = 'center';
    //         ctx.textBaseline = 'middle'; // Ensure vertical alignment
    //         // This line already aligns the text in the middle
    //         const padding = 1; // Increased padding around the text
    //         const textWidth = ctx.measureText(label).width;
    //         // const backgroundColor =
    //         //   customNode.type === 'organisation'
    //         //     ? 'rgba(255, 201, 71, 0.4)' // Yellow for organisations with 70% transparency
    //         //     : customNode.type === 'project'
    //         //       ? 'rgba(109, 208, 85, 0.4)' // Green for projects with 70% transparency
    //         //       : 'rgba(71, 178, 255, 0.4)'; // Blue for persons with 70% transparency

    //         const backgroundColor = 'rgba(255, 255, 255, 0.7)'; // White background with 70% transparency

    //         ctx.fillStyle = backgroundColor; // Set background color with transparency
    //         ctx.beginPath();
    //         ctx.roundRect(
    //           // customX - textWidth / 2 - padding * 2,
    //           // customY - fontSize / 2 - padding * 2,
    //           customX - textWidth / 2,
    //           customY - fontSize / 2,
    //           textWidth, // Increased padding to make the rectangle wider
    //           fontSize,
    //           10 // Radius for rounded corners
    //         ); // Draw the background rectangle with rounded edges
    //         ctx.fill(); // Fill the background rectangle

    //         ctx.fillStyle = 'black'; // Text color
    //         ctx.fillText(
    //           label,
    //           customX,
    //           customY // Offset the label lower based on NODE_R
    //         );
    //       }
    //     } else {
    //       const customX = customNode.x ?? 0;
    //       const customY = (customNode.y ?? 0) + NODE_R + zoom * 1.5;
    //       ctx.font = `${fontSize}px Sans-Serif`;
    //       ctx.textAlign = 'center';
    //       ctx.textBaseline = 'middle';
    //       // ctx.fillStyle = 'white'; // Background color for the label
    //       // const textWidth = ctx.measureText(label).width;
    //       // const padding = 1; // Padding around the text
    //       // ctx.fillRect(
    //       //   customX - textWidth / 2 - padding,
    //       //   customY - fontSize / 2 - padding,
    //       //   textWidth + padding * 1.2,
    //       //   fontSize + padding * 1.2
    //       // ); // Draw the background rectangle
    //       ctx.fillStyle = 'black'; // Text color
    //       ctx.fillText(
    //         label,
    //         customX,
    //         customY // Offset the label lower based on NODE_R
    //       );
    //     }

    //     // // Draw the bounding circle
    //     // if (rootNode) {
    //     //   const boundingRadius = 320; // Same as the bounding circle radius
    //     //   ctx.beginPath();
    //     //   ctx.arc(0, 0, boundingRadius, 0, 2 * Math.PI, false);
    //     //   ctx.fillStyle = 'rgba(255, 255, 255, 0.005)'; // Light shading
    //     //   ctx.fill();
    //     // }
    //   }
    // );

    // Cleanup on unmount
    return () => {
      if (graphRef.current) {
        graphRef.current._destructor && graphRef.current._destructor();
      }
    };
  }, [isLoading, graphRef, rootNode]);

  useEffect(() => {
    if (!graphRef.current) return;

    // Update hover behavior based on showLabels
    graphRef.current.onNodeHover(
      !showLabels && rootNode
        ? () => {}
        : (
            node: ForceGraphNode | null,
            previousNode: ForceGraphNode | null
          ) => {
            highlightNodes.clear();
            highlightLinks.clear();
            if (node) {
              const customNode = node as GraphNode;
              highlightNodes.add(customNode);
              setHoverNode(node ? (node as GraphNode) : null);
            }
          }
    );

    graphRef.current.nodeCanvasObject(
      (
        node: ForceGraphNode,
        ctx: CanvasRenderingContext2D,
        globalScale: number
      ) => {
        const customNode = node as GraphNode;

        const NODE_R = node.id === rootNode?.id ? 8 : 6;

        // Draw the grey outline
        ctx.beginPath();
        if (customNode.type === 'organisation') {
          ctx.moveTo((customNode.x ?? 0) - NODE_R, customNode.y ?? 0);
          ctx.lineTo(customNode.x ?? 0, (customNode.y ?? 0) - NODE_R);
          ctx.lineTo((customNode.x ?? 0) + NODE_R, customNode.y ?? 0);
          ctx.lineTo(customNode.x ?? 0, (customNode.y ?? 0) + NODE_R);
          ctx.closePath();
        } else if (customNode.type === 'project') {
          const angle = (Math.PI * 2) / 6;
          ctx.moveTo(
            (customNode.x ?? 0) + NODE_R * Math.cos(0),
            (customNode.y ?? 0) + NODE_R * Math.sin(0)
          );
          for (let i = 1; i <= 6; i++) {
            ctx.lineTo(
              (customNode.x ?? 0) + NODE_R * Math.cos(i * angle),
              (customNode.y ?? 0) + NODE_R * Math.sin(i * angle)
            );
          }
        } else {
          ctx.arc(
            customNode.x ?? 0,
            customNode.y ?? 0,
            NODE_R + 2, // Slightly larger radius for the outline
            0,
            2 * Math.PI,
            false
          );
        }
        // ctx.fillStyle =
        //   customNode.type === 'organisation'
        //     ? 'rgba(100, 150, 200, 0.2)' // Light blue for organisations
        //     : customNode.type === 'project'
        //       ? 'rgba(100, 200, 100, 0.2)' // Light green for projects
        //       : 'rgba(200, 100, 150, 0.2)'; // Light pink for persons
        // ctx.fill();

        // // Draw the hover effect
        // if (highlightNodes.has(node as GraphNode)) {
        //   ctx.beginPath();
        //   if (customNode.type === 'organisation') {
        //     ctx.moveTo((customNode.x ?? 0) - NODE_R, customNode.y ?? 0);
        //     ctx.lineTo(customNode.x ?? 0, (customNode.y ?? 0) - NODE_R);
        //     ctx.lineTo((customNode.x ?? 0) + NODE_R, customNode.y ?? 0);
        //     ctx.lineTo(customNode.x ?? 0, (customNode.y ?? 0) + NODE_R);
        //     ctx.closePath();
        //   } else if (customNode.type === 'project') {
        //     const angle = (Math.PI * 2) / 6;
        //     ctx.moveTo(
        //       (customNode.x ?? 0) + NODE_R * Math.cos(0),
        //       (customNode.y ?? 0) + NODE_R * Math.sin(0)
        //     );
        //     for (let i = 1; i <= 6; i++) {
        //       ctx.lineTo(
        //         (customNode.x ?? 0) + NODE_R * Math.cos(i * angle),
        //         (customNode.y ?? 0) + NODE_R * Math.sin(i * angle)
        //       );
        //     }
        //   } else {
        //     ctx.arc(
        //       customNode.x ?? 0,
        //       customNode.y ?? 0,
        //       NODE_R + 4, // Slightly larger radius for the hover effect
        //       0,
        //       2 * Math.PI,
        //       false
        //     );
        //   }
        //   ctx.fillStyle = 'white'; // Change hover color to white
        //   ctx.fill();
        // }

        // Draw the node
        ctx.beginPath();
        const x = customNode.x ?? 0;
        const y = customNode.y ?? 0;

        if (customNode.type === 'organisation') {
          ctx.moveTo(x - NODE_R, y);
          ctx.lineTo(x, y - NODE_R);
          ctx.lineTo(x + NODE_R, y);
          ctx.lineTo(x, y + NODE_R);
          ctx.closePath();
        } else if (customNode.type === 'project') {
          const angle = (Math.PI * 2) / 6;
          ctx.moveTo(x + NODE_R * Math.cos(0), y + NODE_R * Math.sin(0));
          for (let i = 1; i <= 6; i++) {
            ctx.lineTo(
              x + NODE_R * Math.cos(i * angle),
              y + NODE_R * Math.sin(i * angle)
            );
          }
        } else {
          ctx.arc(x, y, NODE_R, 0, 2 * Math.PI, false);
        }

        const img = imageCache[customNode.id];
        if (img) {
          ctx.save();
          ctx.clip();
          ctx.drawImage(img, x - NODE_R, y - NODE_R, NODE_R * 2, NODE_R * 2);
          ctx.restore();
        } else {
          ctx.fillStyle =
            customNode.type === 'organisation'
              ? '#FFC947' // Yellow for organisations
              : customNode.type === 'project'
                ? '#6DD055' // Green for projects
                : '#47B2FF'; // Blue for persons
          ctx.fill();
        }

        // Draw the border depending on the color
        ctx.strokeStyle =
          customNode.type === 'organisation'
            ? '#FFC947' // Yellow for organisations
            : customNode.type === 'project'
              ? '#6DD055' // Green for projects
              : '#47B2FF'; // Blue for persons
        ctx.lineWidth = 0.5;
        ctx.stroke();

        const label = nodeDetails[customNode.id]?.name || 'Unknown'; // Get the node ID as a label, default to 'Unknown' if undefined
        const fontSize = 12 / globalScale;

        // Draw the label text only if the node is hovered
        if (
          (highlightNodes.has(customNode) && customNode.id !== rootNode?.id) ||
          (!showLabels && rootNode && customNode.id !== rootNode?.id)
        ) {
          const customX = customNode.x ?? 0;
          const customY = (customNode.y ?? 0) - NODE_R * 1 * (rootNode ? 1 : 0);
          ctx.font = `${fontSize}px Sans-Serif`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle'; // Ensure vertical alignment
          const padding = 3; // Increased padding around the text
          const maxWidth = 150 * (1 / globalScale); // Maximum width for the label
          const words = label.split(' ');
          let line = '';
          const lines = [];
          for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const testWidth = ctx.measureText(testLine).width;
            if (testWidth > maxWidth && n > 0) {
              lines.push(line);
              line = words[n] + ' ';
            } else {
              line = testLine;
            }
          }
          lines.push(line);

          const textHeight = fontSize * lines.length;
          const textWidth = Math.max(
            ...lines.map((line) => ctx.measureText(line).width)
          );

          const backgroundColor = 'rgba(255, 255, 255, 0.7)'; // White background with 70% transparency

          ctx.fillStyle = backgroundColor; // Set background color with transparency
          ctx.beginPath();
          ctx.roundRect(
            customX - textWidth / 2,
            customY - textHeight / 2,
            textWidth, // Increased padding to make the rectangle wider
            textHeight,
            10 // Radius for rounded corners
          ); // Draw the background rectangle with rounded edges
          ctx.fill(); // Fill the background rectangle

          ctx.fillStyle = 'black'; // Text color
          lines.forEach((line, index) => {
            ctx.fillText(
              line,
              customX,
              customY - textHeight / 2 + fontSize * (index + 0.5) // Offset the label lower based on NODE_R
            );
          });
        }
        // } else {
        //   const customX = customNode.x ?? 0;
        //   const customY = (customNode.y ?? 0) + NODE_R + zoom * 1.5;
        //   ctx.font = `${fontSize}px Sans-Serif`;
        //   ctx.textAlign = 'center';
        //   ctx.textBaseline = 'middle'; // Ensure vertical alignment
        //   const padding = 1; // Increased padding around the text
        //   const maxWidth = 50 * globalScale; // Maximum width for the label
        //   console.log(maxWidth);
        //   const words = label.split(' ');
        //   let line = '';
        //   const lines = [];
        //   for (let n = 0; n < words.length; n++) {
        //     const testLine = line + words[n] + ' ';
        //     const testWidth = ctx.measureText(testLine).width;
        //     if (testWidth > maxWidth && n > 0) {
        //       lines.push(line);
        //       line = words[n] + ' ';
        //     } else {
        //       line = testLine;
        //     }
        //   }
        //   lines.push(line);

        //   const textHeight = fontSize * lines.length;
        //   const textWidth = Math.max(
        //     ...lines.map((line) => ctx.measureText(line).width)
        //   );

        //   const backgroundColor = 'rgba(255, 255, 255, 0.7)'; // White background with 70% transparency

        //   ctx.fillStyle = backgroundColor; // Set background color with transparency
        //   ctx.beginPath();
        //   ctx.roundRect(
        //     customX - textWidth / 2,
        //     customY - textHeight / 2,
        //     textWidth, // Increased padding to make the rectangle wider
        //     textHeight,
        //     10 // Radius for rounded corners
        //   ); // Draw the background rectangle with rounded edges
        //   ctx.fill(); // Fill the background rectangle

        //   ctx.fillStyle = 'black'; // Text color
        //   lines.forEach((line, index) => {
        //     ctx.fillText(
        //       line,
        //       customX,
        //       customY - textHeight / 2 + fontSize * (index + 0.5) // Offset the label lower based on NODE_R
        //     );
        //   });
        // }
      }
    );
  }, [showLabels, rootNode]); // Only depends on showLabels and rootNode

  // useEffect(() => {
  //   console.log(viewMode);
  // }, [viewMode]);

  // useEffect(() => {
  //   // Check if nodes and links are available
  //   if (gData.nodes.length > 0 && gData.links.length > 0) {
  //     setIsDataReady(true); // Set data as ready
  //   }
  // }, [gData.nodes, gData.links]); // Run this effect when gData changes

  // useEffect(() => {
  //   console.log('isDataReady', isDataReady);
  //   if (!isDataReady) return;
  //   // Log original nodes to check their x and y values
  //   console.log('Original nodes:', gData.nodes);

  //   // Create label nodes with x and y values from original nodes
  //   const newLabelNodes: GraphNode[] = gData.nodes.map((node) => {
  //     const x = node.x ?? 0; // Default to 0 if undefined
  //     const y = node.y ?? 0; // Default to 0 if undefined
  //     console.log(node);
  //     console.log(`Creating label for node ${node.id}: x=${x}, y=${y}`); // Log x and y values
  //     return {
  //       id: node.id * 100,
  //       x: x,
  //       y: y,
  //       fx: x,
  //       fy: y,
  //       isLabel: true,
  //       collapsed: false, // Default value for collapsed
  //       childLinks: [], // Initialize as empty array
  //       parentLinks: [], // Initialize as empty array
  //       type: 'label', // Set type as 'label'
  //     };
  //   });
  //   // setLabelNodes(newLabelNodes); // Update the state with new label nodes

  //   // Update the graph data with label nodes
  //   graphRef.current.graphData({
  //     nodes: [...gData.nodes, ...newLabelNodes],
  //     links: gData.links,
  //   });
  // }, [isDataReady, graphRef]);

  const resetGraph = () => {
    gData.nodes.forEach((node) => {
      node.collapsed = false; // Uncollapse all nodes
      node.fx = undefined; // Remove fixed x position
      node.fy = undefined; // Remove fixed y position
    });
    setPrevRootNode(rootNode);

    setHighlightNodes(new Set());
    setHighlightLinks(new Set());
    graphRef.current.graphData(getPrunedTree());
    // Set the default zoom level when the graph is first loaded
    graphRef.current.zoom(1.5); // Set your desired default zoom level here
    setZoom(1.5);
    // Re-center the graph on the root node
    if (rootNode && prevXCoord && prevYCoord) {
      graphRef.current.centerAt(prevXCoord + 150 ?? 0, prevYCoord + 50 ?? 0); // Adjust the duration as needed
    } else {
      graphRef.current.centerAt(0, 0, 1000); // Default center if no root node
    }
    setRootNode(null);
  };

  const zoomIn = () => {
    const currentZoom = graphRef.current.zoom();
    graphRef.current.zoom(currentZoom * 1.2);
  };

  const zoomOut = () => {
    const currentZoom = graphRef.current.zoom();
    graphRef.current.zoom(currentZoom * 0.8);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row', // Keep the row direction for sidebar and graph
        height: '100vh',
        width: '100vw',
      }}
    >
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
          }}
        >
          <LoadingLogo width={50} height={50} />
        </div>
      )}
      {!isLoading && (
        <>
          {sidebarVisible &&
            rootNode && ( // Conditionally render the sidebar first
              <div
                style={{
                  width: '600px',
                  backgroundColor: '#FFFFFF',
                  borderRight: '1px solid #000000',
                  zIndex: 3, // Set a higher zIndex for the sidebar
                  position: 'relative', // Ensure it has a stacking context
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100vh', // Ensure the sidebar takes the full height of the viewport
                }}
              >
                <div
                  style={{
                    backgroundColor: '#F6F6F6',
                    padding: '1.5rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    height: '100px',
                  }}
                >
                  <div
                    style={{
                      flexDirection: 'row',
                      height: '100%',
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontFamily: 'IBM Plex Serif',
                            fontSize: '30px',
                            fontWeight: 400,
                            marginBottom: 0,
                          }}
                        >
                          {nodeDetails[rootNode?.id]?.name}
                        </span>
                        {/* <FontAwesomeIcon icon={faCheck} /> */}
                      </div>
                      <div
                        style={{
                          fontSize: '12px',
                          color: '#9E9E9E',
                          marginBottom: '40px',
                        }}
                      >
                        {nodeDetails[rootNode?.id]?.pronouns}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '15px',
                      }}
                    >
                      <FontAwesomeIcon icon={faLocationDot} color="#9E9E9E" />
                      <span
                        style={{
                          fontFamily: 'IBM Plex Sans, sans-serif',
                          fontSize: '16px',
                          fontWeight: 400,
                          textAlign: 'left',
                        }}
                      >
                        {nodeDetails[rootNode?.id]?.location}
                      </span>
                    </div>
                  </div>
                  <div style={{ width: '110px', overflow: 'hidden' }}>
                    {imageMap[rootNode?.id] ? (
                      <img
                        src={imageMap[rootNode?.id]}
                        alt={`Image for ${nodeDetails[rootNode?.id]?.name}`}
                        style={{
                          width: '110px', // Clip the image to 110px width
                          height: '110px',
                          backgroundColor: '#b0b0b0',
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <DefaultImage width={110} height={110} />
                    )}
                  </div>
                </div>
                <div
                  key={rootNode.id}
                  style={{
                    backgroundColor: 'white',
                    padding: '1.5rem',
                    flex: 1,
                    overflowY: 'auto', // Change to 'auto' for better handling
                    maxHeight: 'calc(100vh - 260px)', // Set a max height to prevent cutting off
                  }}
                >
                  <h4
                    style={{
                      marginTop: 0,
                      marginBottom: 5,
                      fontWeight: 'bold',
                      fontFamily: 'IBM Plex Sans, sans-serif',
                      color: '#9E9E9E',
                    }}
                  >
                    BIO
                  </h4>
                  <p
                    style={{
                      fontFamily: 'IBM Plex Sans, sans-serif',
                      fontSize: '14px',
                      lineHeight: '20px',
                      fontWeight: 400,
                    }}
                  >
                    {nodeDetails[rootNode?.id]?.description ||
                      'No bio available.'}
                  </p>
                  <h4
                    style={{
                      marginTop: 20,
                      marginBottom: 0,
                      fontWeight: 'bold',
                      fontFamily: 'IBM Plex Sans, sans-serif',
                      color: '#9E9E9E',
                    }}
                  >
                    LINKS
                  </h4>
                  {nodeDetails[rootNode?.id]?.links?.length > 0 ? (
                    <ul
                      style={{
                        paddingLeft: '10px',
                        fontFamily: 'IBM Plex Sans, sans-serif',
                        fontSize: '14px',
                        lineHeight: '30px',
                        fontWeight: 400,
                        textAlign: 'left',
                        listStyleType: 'none', // Hide the dot points
                        marginTop: '5px',
                      }}
                    >
                      {nodeDetails[rootNode.id].links.map((link, index) => (
                        <li
                          key={index}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <FontAwesomeIcon
                            icon={linkIconMap[link.type]}
                            style={{
                              marginRight: '15px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          />
                          <a
                            href={link.url}
                            style={{
                              color: 'black',
                              textDecoration: 'none',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                display: 'inline-block',
                                maxWidth: '480px',
                              }}
                            >
                              {link.url}
                            </span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <span>No links available.</span>
                  )}

                  <h4
                    style={{
                      marginTop: 20,
                      marginBottom: 10,
                      fontWeight: 'bold',
                      fontFamily: 'IBM Plex Sans, sans-serif',
                      color: '#9E9E9E',
                    }}
                  >
                    TAGS
                  </h4>
                  <div
                    style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
                  >
                    {nodeDetails[rootNode?.id]?.tags?.length > 0 ? (
                      nodeDetails[rootNode.id].tags.map((tag, index) => (
                        <span
                          key={index}
                          style={{
                            backgroundColor: 'rgba(243, 249, 255, 0.5)',
                            borderRadius: '15px',
                            padding: '5px 10px',
                            border: '1px solid #000000',
                            fontFamily: 'IBM Plex Sans, sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                          }}
                        >
                          {tag.toUpperCase()}
                        </span>
                      ))
                    ) : (
                      <span>No tags available.</span>
                    )}
                  </div>
                  <h4
                    style={{
                      marginTop: 20,
                      marginBottom: 5,
                      fontWeight: 'bold',
                      fontFamily: 'IBM Plex Sans, sans-serif',
                      color: '#9E9E9E',
                    }}
                  >
                    {rootNode.type === 'person'
                      ? 'ROLES'
                      : rootNode.type === 'organisation'
                        ? 'PROJECTS'
                        : ''}
                  </h4>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      marginLeft: '10px',
                      textAlign: 'left',
                      fontFamily: 'IBM Plex Sans, sans-serif',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '26px',
                    }}
                  >
                    {rootNode && nodeDetails[rootNode.id].type === 'person' ? ( // Check if rootNode is of type 'person'
                      <div>
                        {nodeDetails[rootNode.id].roles
                          ?.sort((a, b) => {
                            // Sort by targetNode type (organisation first)
                            const targetNodeA = nodeDetails.find(
                              (node) => node.id === a.targetId
                            );
                            const targetNodeB = nodeDetails.find(
                              (node) => node.id === b.targetId
                            );

                            if (
                              targetNodeA?.type === 'organisation' &&
                              targetNodeB?.type !== 'organisation'
                            ) {
                              return -1;
                            }
                            if (
                              targetNodeA?.type !== 'organisation' &&
                              targetNodeB?.type === 'organisation'
                            ) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((role, index) => {
                            const targetNode = nodeDetails.find(
                              (node) => node.id === role.targetId
                            );
                            return (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  justifyContent: 'flex-start',
                                  gap: '5px',
                                }}
                              >
                                {targetNode?.type === 'organisation' && (
                                  <img
                                    src={diamond}
                                    alt="Organisation"
                                    style={{
                                      width: '12px',
                                      height: '12px',
                                      marginRight: '10px',
                                      paddingTop: '7px',
                                    }}
                                  />
                                )}
                                {targetNode?.type === 'person' && (
                                  <img
                                    src={circle}
                                    alt="Project"
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                      marginRight: '10px',
                                    }}
                                  />
                                )}
                                {targetNode?.type === 'project' && (
                                  <img
                                    src={hexagon}
                                    alt="Person"
                                    style={{
                                      width: '10px',
                                      height: '10px',
                                      marginRight: '10px',
                                      paddingTop: '8px',
                                      paddingLeft: '1px',
                                    }}
                                  />
                                )}
                                <span
                                  style={{
                                    width: '200px',
                                    marginRight: '10px',
                                    display: 'inline-block',
                                  }}
                                >
                                  {targetNode?.name}
                                </span>
                                <span
                                  style={{
                                    width: '200px',
                                    marginRight: '10px',
                                    display: 'inline-block',
                                  }}
                                >
                                  {role.role}
                                </span>
                                <span
                                  style={{
                                    width: '30px',
                                    display: 'inline-block',
                                  }}
                                >
                                  {role.startDate}
                                </span>
                                {role.endDate ? <span> - </span> : null}

                                {role.endDate ? (
                                  <span
                                    style={{
                                      width: '30px',
                                      display: 'inline-block',
                                    }}
                                  >
                                    {role.endDate}
                                  </span>
                                ) : null}
                              </div>
                            );
                          })}
                      </div>
                    ) : rootNode.type === 'organisation' ? (
                      <div>
                        {nodeDetails[rootNode.id].projects?.map(
                          (project, index) => {
                            const targetNode = nodeDetails.find(
                              (node) => node.id === project
                            );
                            return (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '5px',
                                }}
                              >
                                {targetNode?.type === 'organisation' && (
                                  <img
                                    src={diamond}
                                    alt="Organisation"
                                    style={{
                                      width: '10px',
                                      height: '10px',
                                      marginRight: '10px',
                                    }}
                                  />
                                )}
                                {targetNode?.type === 'person' && (
                                  <img
                                    src={circle}
                                    alt="Project"
                                    style={{
                                      width: '15px',
                                      height: '10px',
                                      marginRight: '10px',
                                    }}
                                  />
                                )}
                                {targetNode?.type === 'project' && (
                                  <img
                                    src={hexagon}
                                    alt="Person"
                                    style={{
                                      width: '10px',
                                      height: '10px',
                                      marginRight: '10px',
                                    }}
                                  />
                                )}
                                <span
                                  style={{
                                    width: '400px',
                                    display: 'inline-block',
                                  }}
                                >
                                  {targetNode?.name}
                                </span>
                                <span
                                  style={{
                                    width: '200px',
                                    display: 'inline-block',
                                  }}
                                >
                                  {targetNode?.date
                                    ? targetNode?.date
                                        .split(',')
                                        .reduce((acc, year, index, arr) => {
                                          const trimmedYear = year.trim();
                                          if (index === 0) return trimmedYear; // First year
                                          if (index === arr.length - 1)
                                            return `${acc}, ${trimmedYear}`; // Last year
                                          if (
                                            parseInt(trimmedYear) -
                                              parseInt(
                                                arr[index - 1].trim()
                                              ) ===
                                            1
                                          ) {
                                            return acc; // Continue if consecutive
                                          }
                                          return `${acc} - ${arr[index - 1].trim()}, ${trimmedYear}`; // Add range
                                        }, '')
                                    : targetNode?.date}
                                </span>
                              </div>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </div>
              </div>
            )}
          <div style={{ flex: 1, position: 'relative', zIndex: 1 }}>
            {/* Set zIndex for the graph container */}
            <div
              style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                zIndex: 2, // Keep the button container above the graph
              }}
            >
              {/* <button onClick={zoomIn} style={{ margin: '5px' }}>
            Zoom In
          </button>
          <button onClick={zoomOut} style={{ margin: '5px' }}>
            Zoom Out
          </button> */}
              {rootNode && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <button
                    onClick={resetGraph}
                    style={{
                      margin: '5px',
                      border: '0px solid #000000',
                      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.5)',
                      borderRadius: '20px',
                      padding: '10px 15px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '5px',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon icon={faMap} />
                    Map View
                  </button>
                  <button
                    onClick={toggleLabelVisibility}
                    style={{
                      margin: '5px',
                      border: '0px solid #000000',
                      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.5)',
                      borderRadius: '20px',
                      padding: '10px 15px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '5px',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                    {showLabels ? 'Show Labels' : 'Hide Labels'}
                  </button>
                </div>
              )}
            </div>
            <div
              ref={containerRef}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: '#FFFFFF',
                zIndex: 1, // Ensure the graph is below the sidebar
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SimpleGraph;
