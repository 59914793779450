import { Link } from '../Simple';
import { GraphNode } from '../Simple';
import { GraphLink } from '../Simple';

export type Role = {
  targetId: number;
  role: string;
  startDate?: string;
  endDate?: string;
};

// export type Project = {
//   targetId: number;
// };

export type NodeDetails = {
  id: number;
  type: 'person' | 'organisation' | 'project';
  name: string;
  pronouns?: string;
  location?: string;
  date?: string;
  description: string;
  links: Link[];
  tags: string[];
  roles?: Role[]; // Optional property for roles
  projects?: number[];
};

const peopleNodeDetails: NodeDetails[] = [
  {
    id: 0,
    type: 'person',
    name: 'Lana Nguyen',
    pronouns: 'she',
    location: 'Wurundjeri Woi Wurrung Country',
    description:
      'Lana Nguyen is a producer, curator and community arts worker interested in experimental, site-specific and context-driven work. Interested in the space where community and contemporary practice align, she looks to create work that drives conversation and connection. Recently, she held the role of Executive Producer of the Due West Arts Festival with Footscray Community Arts Centre, and continues to work with organisations and programs such as MoreArt Public Art Festival, ArtPlay/SIGNAL, Platform Arts and Hyphenated Projects, as well as working on various independent projects with artists across art forms.',
    links: [
      { url: 'https://www.linkedin.com/in/lana-nguyen/', type: 'linkedin' },
    ],
    tags: [],
    roles: [
      {
        targetId: 55,
        role: 'Co-instigator',
        startDate: '',
        endDate: '',
      },
      {
        targetId: 69,
        role: 'Member',
      },
    ],
  },
  {
    id: 1,
    type: 'person',
    name: 'Berish Bilander',
    pronouns: 'he',
    location: 'Wurundjeri Woi Wurrung Country',
    description:
      'Berish Bilander is a composer, musician, and activist dedicated to climate and social justice. He has co-written and toured with a range of artists including Vika and Linda Bull, Jaimi Faulkner, and new music ensemble Motion. As an activist, Berish has worked with local climate action groups to support state, and national campaigns run through Friends of the Earth, and in 2013 helped found Public Transport Not Traffic. He is fellow of the prestigious Banff International Jazz and Creative Music Program and the Change Agency’s Community Organising Fellowship.',
    links: [
      {
        url: 'https://www.linkedin.com/in/berish-bilander-38751880/',
        type: 'linkedin',
      },
    ],
    tags: ['composer', 'musician', 'activist'],
    roles: [
      {
        targetId: 54,
        role: 'CEO',
        startDate: '2019',
        endDate: 'Now',
      },
      {
        targetId: 54,
        role: 'Outreach Campaigner',
        startDate: '2016',
        endDate: '2019',
      },
      {
        targetId: 69,
        role: 'Member',
      },
    ],
  },
  {
    id: 2,
    type: 'person',
    name: 'Eliki Reade',
    pronouns: 'they',
    location: 'Wurundjeri Woi Wurrung Country',
    description:
      'Eliki Reade is an Interdependent Producer, curator and artist of kailoma-Fijian heritage. Eliki works with many forms of storytelling and the ways it is creatively embodied, engaging with work that centres the practice, creating critical connection. They wear multiple creative hats including Program Coordinator at MPavilion, Co-instigator with Lana Nguyen for A Climate For Arts, a collaborator with Big Four Holiday Camp – Aaron Claringbold, Jack Mitchell and Rebecca McCauley – amongst many other personally fun and exciting projects and loves.',
    links: [
      {
        url: 'https://www.linkedin.com/in/eliki-reade-515941116/',
        type: 'linkedin',
      },
    ],
    tags: [],
    roles: [
      {
        targetId: 55,
        role: 'Co-instigator',
      },
      {
        targetId: 69,
        role: 'Member',
      },
      {
        targetId: 19,
        role: 'Grant Recipient',
        startDate: '2022',
        endDate: '2024',
      },
    ],
  },
  {
    id: 3,
    type: 'person',
    name: 'Claire G. Coleman',
    pronouns: 'she',
    location: 'Wurundjeri Woi Wurrung Country',
    description:
      'Claire G. Coleman is cNoongar woman whose family have belonged to the south coast of Western Australia since long before history started being recorded. She writes fiction, essays, poetry and art criticism while either living in Naarm (Melbourne) or on the road. Born in Whadjuk Noongar Boodjar (Perth), away from her ancestral country she has lived most of her life in Victoria and most of that in and around Naarm (Melbourne).',
    links: [{ url: 'https://clairegcoleman.com/', type: 'website' }],
    tags: [],
    roles: [
      {
        targetId: 53,
        role: 'Co-instigator',
      },
      {
        targetId: 60,
        role: 'Writer',
      },
      {
        targetId: 45,
        role: 'Artist',
        startDate: '2021',
      },
      {
        targetId: 11,
        role: 'Artist',
        startDate: '2023',
        endDate: 'Now',
      },
      {
        targetId: 13,
        role: 'Author',
        startDate: '2021',
      },
      {
        targetId: 14,
        role: 'Author',
        startDate: '2022',
      },
      {
        targetId: 15,
        role: 'Author',
        startDate: '2019',
      },
      {
        targetId: 16,
        role: 'Author',
        startDate: '2017',
      },
    ],
  },
  {
    id: 4,
    type: 'person',
    name: 'Harry Shang Lun Lee',
    pronouns: 'he, they',
    location: 'Wurundjeri Woi Wurrung Country',
    description:
      'I’m an antidisciplinary artist and management consultant. At Bain & Company, I help clients achieve extraordinary results. On the weekends, I make bold interactive experiences with my creative studio PlayReactive. My work frequently explores complex systems and wicked problems of society – topics such as democracy, capitalism, and climate crisis. I’m strongly driven by the pursuit of environmental and social justice. I’m currently living in Naarm on the unceded lands of the Wurundjeri Woi-wurrung people. I pay my respects to their Elders past and present.',
    links: [
      { url: 'http://hellothisismywebsite.com', type: 'website' },
      { url: 'https://www.linkedin.com/in/leeshanglun/', type: 'linkedin' },
    ],
    tags: [],
    roles: [
      {
        targetId: 48,
        role: 'Director',
        startDate: '??',
        endDate: 'Now',
      },
      {
        targetId: 44,
        role: 'Artist',
        startDate: '2015',
      },
      {
        targetId: 21,
        role: 'Artist',
        startDate: '??',
      },
      {
        targetId: 22,
        role: 'Designer',
        startDate: '??',
      },
      {
        targetId: 23,
        role: 'Designer',
        startDate: '??',
      },
      {
        targetId: 24,
        role: 'Organiser',
        startDate: '??',
      },
      {
        targetId: 40,
        role: 'Game Designer',
        startDate: '2014',
      },
      {
        targetId: 17,
        role: 'Consultant',
        startDate: '2021',
      },
      {
        targetId: 17,
        role: 'Artist',
        startDate: '2016',
        endDate: '2019',
      },
      {
        targetId: 17,
        role: 'Artist',
        startDate: '2021',
      },
      {
        targetId: 11,
        role: 'Artist',
        startDate: '2023',
        endDate: 'Now',
      },
      {
        targetId: 69,
        role: 'Member',
      },
    ],
  },
  {
    id: 5,
    type: 'person',
    name: 'Jen Rae',
    pronouns: 'she, they',
    location: 'Djaara Country',
    description:
      'Dr Jen Rae is an award-winning artist and researcher of Canadian Scottish-Métis (Indigenous) descent living on unceded Djaara Country (Castlemaine) Australia. She is recognised for her practice and expertise situated at the intersections of art, speculative futures and climate emergency disaster adaptation + resilience – predominantly articulated through transdisciplinary collaborations, multi-platform projects, community alliances and public pedagogies. She is the Co-founder and Creative Research Lead at the Centre for Reworlding, Co-founder (with American artist Dawn Weleski of Conflict Kitchen) and Director of Fair Share Fare, a member of the National Task Force for Creative Recovery, awarded the prestigious 2023 Creative Australia Fellowship for Emerging and Experimental Art, the 2024 Australian National University’s H.C. Coombs Creative Fellowship and the 2024/2025 International Visiting Artist Residency at the University of Wisconsin.',
    links: [
      { url: 'https://www.jenraeis.com/', type: 'website' },
      { url: 'https://www.linkedin.com/in/jenrae/', type: 'linkedin' },
    ],
    tags: [''],
    roles: [
      {
        targetId: 53,
        role: 'Co-instigator',
        startDate: '2021',
        endDate: 'Now',
      },
      {
        targetId: 17,
        role: 'Artist',
        startDate: '2016',
        endDate: '2019',
      },
      {
        targetId: 17,
        role: 'Artist',
        startDate: '2021',
      },
      {
        targetId: 11,
        role: 'Artist',
        startDate: '2023',
        endDate: 'Now',
      },
      {
        targetId: 45,
        role: 'Artist',
        startDate: '2021',
      },
      {
        targetId: 59,
        role: 'Artist',
        startDate: '2017',
      },
      {
        targetId: 62,
        role: 'Artist',
        startDate: '2019',
        endDate: '2021',
      },
      {
        targetId: 61,
        role: 'Artist',
        startDate: '2020',
      },
      {
        targetId: 58,
        role: 'Co-instigator',
        startDate: '2021',
        endDate: 'Now',
      },
      {
        targetId: 12,
        role: 'Contributor',
      },
      {
        targetId: 69,
        role: 'Member',
      },
      {
        targetId: 65,
        role: 'Artist',
        startDate: '2022',
      },
      {
        targetId: 70,
        role: 'Fellowship Program Participant',
        startDate: '2009',
      },
    ],
  },
  {
    id: 6,
    type: 'person',
    name: 'Angharad Wynne-Jones',
    pronouns: 'she',
    location: 'Wurundjeri Woi Wurrung Country',
    description:
      'Angharad Wynne-Jones (she/her) is Cymry (Welsh) Australian and lives on the unceded lands of the Kulin Nation in Narrm (Melbourne). Angharad was born on the traditional homelands of the Council of the Three Fires: the Ojibwe, Odawa, and Potawatomi Nations (Chicago). She is currently Head of Creative Engagement at Arts Centre Melbourne, leading a team of creative producers in innovative participative arts programs of access and inclusion, young people, public realm and creative learning that engage with many thousands of Victorians annually. From 2011-2017 she was Artistic Director at Arts House, City of Melbourne, contemporary arts production house and program, where she initiated Australia’s first international biennial Festival of Live Art, Green Room award winning Going Nowhere an environmentally sustainable international arts event, and Refuge – a five year publicly engaged investigation into the role of cultural institutions in climate catastrophes. She is Founder Director of TippingPoint Australia developing international and local projects with artists, scientists and communities energising the cultural response to climate change and co-designed and delivered NIDA’s MFA Cultural Leadership course 2015-2018.',
    links: [
      {
        url: 'https://www.linkedin.com/in/angharad-wynne-jones-she-her-2184b412/',
        type: 'linkedin',
      },
    ],
    tags: [],
    roles: [
      { targetId: 30, role: 'Artist', startDate: '2010' },
      { targetId: 31, role: 'Artist', startDate: '2011' },
      { targetId: 32, role: 'Artist', startDate: '2011' },
      { targetId: 33, role: 'Artist', startDate: '2011' },
      { targetId: 34, role: 'Artist', startDate: '2012' },
      { targetId: 35, role: 'Artist', startDate: '2013' },
      { targetId: 36, role: 'Artist', startDate: '2013' },
      { targetId: 37, role: 'Artist', startDate: '2011', endDate: '2012' },
      { targetId: 37, role: 'Artist', startDate: '2014' },
      { targetId: 38, role: 'Artist', startDate: '2011', endDate: '2014' },
      { targetId: 39, role: 'Artist', startDate: '2014' },
      { targetId: 40, role: 'Artist', startDate: '2014' },
      { targetId: 41, role: 'Artist', startDate: '2015' },
      { targetId: 42, role: 'Artist', startDate: '2015' },
      { targetId: 43, role: 'Artist', startDate: '2015' },
      { targetId: 44, role: 'Artist', startDate: '2015', endDate: '2016' },
      { targetId: 20, role: 'Artist', startDate: '2017' },
      {
        targetId: 50,
        role: 'Head of Creative Engagement',
        startDate: '2017',
        endDate: '2021',
      },
      {
        targetId: 49,
        role: 'Head of Audience Engagement',
        startDate: '2021',
        endDate: '2024',
      },
      {
        targetId: 51,
        role: 'Founding Director and Producer',
        startDate: '2009',
        endDate: '2024',
      },
      {
        targetId: 60,
        role: 'Steering Group',
        startDate: '2021',
      },
      {
        targetId: 69,
        role: 'Member',
      },
      {
        targetId: 57,
        role: 'Artistic Director',
        startDate: '2011',
        endDate: '2017',
      },
    ],
  },
  {
    id: 7,
    type: 'person',
    name: 'Catherine Jones',
    pronouns: 'she',
    location: 'Wurundjeri Woi Wurrung Country',
    description:
      'Catherine Jones is a highly respected leader experienced in guiding complex organisations and programs. She has comprehensive strategic, stakeholder, resource, and operational management experience in organisations of a diversity of scale and complexity. With strong relationship management and rapport building across internal and external stakeholders, Catherine is an innovative thinker with the ability to drive outcomes and problem solve. She is an authentic people leader able to develop high performing and committed teams. Experienced in change management, one of her key strengths is her capacity to forge consensus with diverse stakeholders in complex settings to deliver large and often challenging reform and innovation. With government and advocacy experience she adept at working in political environments. Catherine is keen to explore using her extensive leadership skills in other for purposes organisations focused on social impact. Catherine has significant experience in non-profit governance a Chair, Board Director, Company Secretary, and Committee member for organisations including Theatre Network Australia, Chunky Move, Malthouse Theatre, Arena Theatre, and Kage Physical Theatre.',
    links: [
      {
        url: 'https://www.linkedin.com/in/catherinel-jones/?originalSubdomain=au',
        type: 'linkedin',
      },
    ],
    tags: [],
    roles: [
      {
        targetId: 57,
        role: 'General Manager',
        startDate: '2015',
        endDate: '2018',
      },
      {
        targetId: 47,
        role: 'Director',
        startDate: '2018',
        endDate: '2024',
      },
      {
        targetId: 69,
        role: 'Member',
      },
    ],
  },
  {
    id: 8,
    type: 'person',
    name: 'Emma Bosworth',
    pronouns: 'she',
    location: 'Gubbi Gubbi Country',
    description:
      'Emma Bosworth is an accomplished Queensland singer-songwriter and Music Project Manager. An integral team member for national not-for-profit organisation Green Music Australia (GMA), Emma works closely with a core group of musicians and music industry to bring awareness to the environmental impact of our industry and the destruction of our climate. At GMA, Emma heads the Green Action Program, an 8-week training program to help music businesses understand, monitor and improve their environmental impact. She’s also developed with technical partners, iSystain, Amidesi - an Australian music industry wide tool to track its environmental and social impact. She is also the founder of songwriter’s retreat now in its fifth year, Wild Mountains Songs, a project that reconnects established mid-career artists to nature to create new works.',
    links: [
      {
        url: 'https://www.linkedin.com/in/emma-bosworth-b5304175/',
        type: 'linkedin',
      },
    ],
    tags: [],
    roles: [
      {
        targetId: 54,
        role: 'National Projects & Partnerships Manager',
        startDate: '2020',
        endDate: 'Now',
      },
      {
        targetId: 54,
        role: 'Climate Change Campaigner',
        startDate: '2017',
        endDate: '2018',
      },
      {
        targetId: 54,
        role: 'Co-CEO',
        startDate: '2018',
        endDate: '2020',
      },
      {
        targetId: 69,
        role: 'Member',
      },
    ],
  },
  {
    id: 9,
    type: 'person',
    name: 'Jackson Nolan',
    pronouns: 'he',
    location: 'Wurundjeri Woi Wurrung Country',
    description:
      'Combining social impact and technology, with a broad skillset in software development, data engineering, and business analysis.',
    links: [
      { url: 'https://www.linkedin.com/in/jacksonnolan/', type: 'linkedin' },
    ],
    tags: [],
    roles: [
      {
        targetId: 48,
        role: 'Developer',
        startDate: '2024',
        endDate: 'Now',
      },
      {
        targetId: 11,
        role: 'Developer',
        startDate: '2024',
        endDate: 'Now',
      },
    ],
  },
  {
    id: 10,
    type: 'person',
    name: 'Kelly McCluskey',
    pronouns: 'she',
    location: 'Whadjuck Noongar Boodjar Country',
    description:
      'Kelli McCluskey is the Chief Executive Artist of pvi collective, an experimental arts organisation. pvi specialise in disruptive works that engage audiences in guided experiences that position them to confront social justice issues, including that of climate change. pvi collective operates on Whadjuck Noongar Boodjar Country (Perth).',
    links: [
      { url: 'https://www.linkedin.com/in/kellimccluskey', type: 'linkedin' },
    ],
    tags: [],
    roles: [
      {
        targetId: 25,
        role: 'Artist',
        startDate: '2023',
        endDate: '2024',
      },
      {
        targetId: 26,
        role: 'Artist',
        startDate: '2019',
        endDate: '2021',
      },
      {
        targetId: 27,
        role: 'Artist',
        startDate: '2023',
        endDate: '',
      },
      {
        targetId: 28,
        role: 'Artist',
        startDate: '2019',
        endDate: '',
      },
      {
        targetId: 29,
        role: 'Artist',
        startDate: '2021',
        endDate: '2022',
      },
      {
        targetId: 69,
        role: 'Member',
      },
    ],
  },
];

const projectNodeDetails: NodeDetails[] = [
  {
    id: 11,
    type: 'project',
    name: 'Bilya',
    date: '2023, 2024',
    description: '',
    links: [{ url: 'http://bilya.net.au', type: 'website' }],
    tags: [],
  },
  {
    id: 12,
    type: 'project',
    name: 'The Relationship is the Project',
    date: '2024',
    description:
      'The Relationship is the Project is a book of provocations, tools and practical tips for those who are currently working or are interested in working with communities. It is intended to be a solid starting point for organisations, project managers, artists, cultural workers and other community-engaged practitioners who want to bring new perspectives and ideas to their work. This project began as a response to growing interest in working better with communities, and in finding out how to develop relationships that foster creativity, cultural engagement and audiences. It aims to be an accessible text that is a place to start learning and return to when you want to know more. In it, you’ll find wisdom from practitioners and thought leaders from across Australia, mostly working within the fields of arts, culture and community development (though their learnings can be applied across a range of sectors and contexts).',
    links: [
      { url: 'https://therelationshipistheproject.com/', type: 'website' },
    ],
    tags: [],
  },
  {
    id: 13,
    type: 'project',
    name: 'Lies, Damned Lies',
    date: '2021',
    description:
      'In Lies, Damned Lies acclaimed author Claire G. Coleman, a proud Noongar woman, takes the reader on a journey through the past, present and future of Australia, lensed through her own experience. Beautifully written, this literary work blends the personal with the political, offering readers an insight into the stark reality of the ongoing trauma of the violent colonisation of the Australian continent. Colonisation in Australia is not over. Colonisation is a process, not an event – and the after-effects will continue as long as there are still people alive who remember it.',
    links: [],
    tags: [],
  },
  {
    id: 14,
    type: 'project',
    name: 'Enclave',
    date: '2022',
    description:
      "From the critically acclaimed author of Terra Nullius comes a novel in the tradition of Margaret Atwood and Naomi Alderman that explores a future of surveillance, disruption and segregation that echoes the horrors of a colonial past. In our turbulent times, Claire G. Coleman's Enclave is a powerful dystopian allegory that confronts the ugly realities of racism, homophobia, surveillance, greed and privilege and the self-destructive distortions that occur when we ignore our shared humanity.",
    links: [],
    tags: [],
  },
  {
    id: 15,
    type: 'project',
    name: 'The Old Lie',
    date: '2019',
    description:
      'Shane Daniels and Romany Zetz have been drawn into a war that is not their own. Lives will be destroyed, families will be torn apart. Trust will be broken. When the war is over, some will return to a changed world. Will they discover that glory is a lie?',
    links: [],
    tags: [],
  },
  {
    id: 16,
    type: 'project',
    name: 'Terra Nullius',
    date: '2017',
    description:
      'The Natives of the Colony are restless. The Settlers are eager to have a nation of peace, and to bring the savages into line. Families are torn apart, reeducation is enforced. This rich land will provide for all. This is not Australia as we know it. This is not the Australia of our history. This TERRA NULLIUS is something new, but all too familiar.',
    links: [],
    tags: [],
  },
  {
    id: 17,
    type: 'project',
    name: 'Refuge',
    date: '2016, 2017, 2018, 2019, 2021',
    description:
      'From droughts to floods, mass displacements to pandemics and heatwaves, Refuge was never just a hypothetical. Refuge drops us in the hot zone of different climate-related disasters. This six-year project offers us new ways to rally as a community and prepare for climate crisis.',
    links: [
      {
        url: 'https://www.artshouse.com.au/artist-opportunities/refuge/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 18,
    type: 'project',
    name: 'Sound Country',
    date: '2022, 2023, 2024',
    description:
      'A comprehensive resource for musicians who want to go green and become confident advocates for change.',
    links: [
      { url: 'https://www.greenmusic.org.au/soundcountry', type: 'website' },
    ],
    tags: [],
  },
  {
    id: 19,
    type: 'project',
    name: 'Unlocking Capacity',
    date: '',
    description: '',
    links: [],
    tags: [],
  },
  {
    id: 20,
    type: 'project',
    name: 'Water Futures',
    date: '2017',
    description:
      'Water Futures is an international, interdisciplinary event involving participants from across Australia and the Asia Pacific, including artists, scientists, Indigenous elders, economists, activists, politicians, diplomats and business people.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2017-water-future',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 21,
    type: 'project',
    name: 'Mapping the Pandemic',
    date: '',
    description: '',
    links: [],
    tags: [],
  },
  {
    id: 22,
    type: 'project',
    name: 'Exodus',
    date: '',
    description: '',
    links: [],
    tags: [],
  },
  {
    id: 23,
    type: 'project',
    name: 'The Pledge',
    date: '',
    description: '',
    links: [],
    tags: [],
  },
  {
    id: 24,
    type: 'project',
    name: 'Ecojam',
    date: '',
    description: '',
    links: [],
    tags: [],
  },
  {
    id: 25,
    type: 'project',
    name: 'social licence watchdogs',
    date: '2023, 2024',
    description:
      'Imagine an imminent future where carbon polluters are held accountable for their damage to the planet. Our latest intervention is a fictional debt collection agency that uses a new scientific method of accounting for CO2.',
    links: [
      {
        url: 'https://pvicollective.com/project/social-licence-watchdogs/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 26,
    type: 'project',
    name: 'artwash award',
    date: '2019, 2020, 2021',
    description:
      'The annual artwash award is a PVI Collective initiative that aims to open up the difficult discussion around the impact of corporate investment in the arts, in particular how extractive industries may cultivate these relationships as strategic tactics to create their “social licence to operate”. This in turn can stifle debate on the impact their polluting activities are having on frontline communities and more broadly, the climate crisis.',
    links: [
      {
        url: 'https://pvicollective.com/frequently-asked-questions/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 27,
    type: 'project',
    name: 'Our Special Envoy',
    date: '2023',
    description:
      'Working with the team at APAM (Australian Performing Arts Market) 2023, Helen repurposed the cost of one international flight and delegate pass, and invited Australian artist Kelli McCluskey (Chief Artist, PVI Collective) to become IBT’s Special Envoy, acting as “her boots on the ground” by attending APAM in her place.',
    links: [
      {
        url: 'https://inbetweentime.co.uk/our-special-envoy/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 28,
    type: 'project',
    name: 'tiny revolutions',
    date: '2019',
    description:
      'Tiny revolutions is a call to arms to develop a series of micro actions to some of the most overwhelmingly urgent issues we face in the 21st century.',
    links: [
      {
        url: 'https://pvicollective.com/project/tiny-revolutions/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 29,
    type: 'project',
    name: 'eaters',
    date: '2021, 2022',
    description:
      'A performance work cunningly disguised as a pub quiz - a rowdy hour of “eatertainment”, where audiences team up to consider what challenges we face as eaters in the midst of a climate emergency.',
    links: [
      { url: 'https://pvicollective.com/project/eaters/', type: 'website' },
    ],
    tags: [],
  },
  {
    id: 30,
    type: 'project',
    name: 'TippingPoint Australia Forums',
    date: '2010',
    description:
      'Art and climate change – re-imagining a global future through dialogue and action. TippingPoint Australia hosted three forums in Melbourne, Sydney, and Brisbane in 2010 for invited international and Australian artists, scientists, and others to explore ways to adapt to and mitigate the effects of climate change functionally, culturally, and socially.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2016/12/15/tippingpoint-australia-forum',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 31,
    type: 'project',
    name: 'Control of the Commons',
    date: '2011',
    description:
      'By making a series of trips along several watercourses in Europe and Australia, Control of the Commons (CoC) aimed to undertake an artistic exploration of the relationship between people and water and how that relationship travels along watercourses.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2011/8/13/2011-control-of-the-commons',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 32,
    type: 'project',
    name: 'The Climate Changers',
    date: '2011',
    description:
      'Climate change is daunting. We have mostly agreed that it’s happening, but it’s big—what can we do about it anyway? What about cyclone-powered electricity grids? Or lightning-run barbecues? Or dog saliva showers? Can we build a machine to fix it? We need to work together, but how do we encourage cooperation? These are some of the ingenious ideas Year 5 children from Milton State School in Brisbane suggested when asked to come up with creative solutions to climate change. The children’s artwork responses are sure to surprise and amaze you.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2011/8/20/2010-exhibition-the-climate-changers',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 33,
    type: 'project',
    name: 'Sustaining the Arts Workshop with Julie’s Bicycle',
    date: '2011',
    description:
      'TippingPoint Australia, Theatre Network Victoria, ArtsHouse, the City of Melbourne, and the British Council presented a workshop for CEOs, Artistic Directors, and arts practitioners committed to or interested in developing a sustainability plan for their work as independent artists, producers, small to medium arts organisations, performing arts venues, or arts festivals.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2011/10/7/2011-sustaining-the-arts-workshop-with-julies-bicycle',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 34,
    type: 'project',
    name: 'Cultivating Green Art: Ideas & Solutions For Environmental Sustainability',
    date: '2012',
    description:
      'TippingPoint Australia and Melbourne Conversations, the City of Melbourne’s free conversation program, invited an audience to join some of Melbourne and Copenhagen’s most exciting cultural innovators as they discussed how artists in their cities responded to the challenge of environmental sustainability. The audiences contributed to the Café Conversations-style evening and had interactive creative conversations on the issues raised.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2011/11/24/2011-cultivating-green-art-ideas-solutions-for-environmental-sustainability',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 35,
    type: 'project',
    name: 'The future – unimaginable or unthinkable?',
    date: '2013',
    description:
      'We needed to reduce emissions, invest in renewables and clean energy, and change our economic reliance on coal and oil—a paradigm shift. How could we contribute to moving this critical agenda forward by imagining futures, understanding and articulating the benefits of a sustainable global future, and rehearsing the enormous cultural, political, and social changes that had to occur to prevent the unthinkable from happening?',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2016/12/5/the-future-unimaginable-or-unthinkable-2013',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 36,
    type: 'project',
    name: 'Outcomes from the Tipping Point Conference in Melbourne',
    date: '2013',
    description:
      'How can cities and communities best plan for climate change? This program seeks to understand future climate risks and to explore how cities, communities, and individuals can best adapt to climate change in the context of complex socio-ecological stress.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2016/12/19/presentation-outcomes-from-the-tipping-point-conference-in-melbourne',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 37,
    type: 'project',
    name: 'Home Art',
    date: '2011, 2012, 2014',
    description:
      'Home art is an international arts project enabling people to make and show art in their own homes in collaboration with leading contemporary artists. From development to presentation, these art works have been created with low environmental impact.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events#:~:text=13-,2014%20Home%20Art,-Sunday%2C%20April%2013',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 38,
    type: 'project',
    name: 'The Climate Changers',
    date: '2011',
    description:
      'Climate change is daunting. We have mostly agreed that it’s happening, but it’s big—what can we do about it anyway? What about cyclone-powered electricity grids? Or lightning-run barbecues? Or dog saliva showers? Can we build a machine to fix it? We need to work together, but how do we encourage cooperation? These are some of the ingenious ideas Year 5 children from Milton State School in Brisbane suggested when asked to come up with creative solutions to climate change. The children’s artwork responses are sure to surprise and amaze you.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2011/8/20/2010-exhibition-the-climate-changers',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 39,
    type: 'project',
    name: 'Going Nowhere',
    date: '2014',
    description:
      'Going Nowhere was an event happening simultaneously on two sides of the globe—in Melbourne and in Cambridge, UK—exploring how artists, communities, and audiences could sustainably generate international creative experiences without getting on a plane.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2014/9/21/2014-going-nowhere',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 40,
    type: 'project',
    name: 'Game On!',
    date: '2014',
    description:
      'Game on! was part forum, part game, and part collaborative action planning—a chance to develop ideas contributing to the seismic cultural shift needed for a desired future.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2016/12/5/game-on-2014-tippingpoint-australia-forum',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 41,
    type: 'project',
    name: 'A Conversation with the Inevitable: Confronting climate change and death',
    date: '2015',
    description:
      'In a unique performative conversation, Angharad Wynne-Jones and Matt Wicking invited grief and bereavement counsellor, Lea Rose, to offer an open session about confronting the end of life and the end of the world as we know it.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2015/5/14/2015-a-conversation-with-the-inevitable-confronting-climate-change-and-death',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 42,
    type: 'project',
    name: 'Spring Lab',
    date: '2015',
    description:
      'Over four chilly days in September, TippingPoint Australia invited a small group of artists, scientists and other interested participants to Daylesford, Victoria to take part in Spring Lab 2015.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2015/9/6/spring-lab',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 43,
    type: 'project',
    name: 'Cop Out',
    date: '2015',
    description:
      'On the final day of the Paris COP (Conference of Parties) talks, where politicians decided our planet’s future, TippingPoint Australia facilitated a virtual forum – COP OUT with an eclectic and esteemed group of scientists, artists, academics, and activists to discuss how art could impact climate change.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2016/11/30/cop-out',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 44,
    type: 'project',
    name: 'The Weather Stations Project',
    date: '2015, 2016',
    description:
      '9 sessions with the Footscray City College Year 9 students focussing on art and sustainability.',
    links: [
      {
        url: 'https://www.tippingpointaustralia.com/new-events/2015/1/1/2015-workshops-with-fcac',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 45,
    type: 'project',
    name: 'REFUGIUM',
    date: '2021',
    description:
      'Refugium is a short film of speculative fiction, by artist-researcher Jen Rae and author Claire G. Coleman. Centred on First Nations knowledge and protocols, Refugium hacks time and compounding existential crises, delves into moral dilemmas of life and death and hones in on child-centered trauma prevention and intergenerational justice in the coming collapse.',
    links: [
      { url: 'https://www.centreforreworlding.com/refugium', type: 'website' },
    ],
    tags: [],
  },

  // Additional projects can be added here following the same structure
];

const organisationNodeDetails: NodeDetails[] = [
  {
    id: 46,
    type: 'organisation',
    name: "Julie's Bicycle",
    description:
      'A pioneering non-profit, mobilising the arts and culture to take action on the climate, nature and justice crisis.',
    links: [{ url: 'https://juliesbicycle.com/', type: 'website' }],
    tags: [],
    projects: [33, 11],
  },
  {
    id: 47,
    type: 'organisation',
    name: 'APAM',
    description:
      'APAM champions contemporary Australian performance nationally and internationally by deepening relationships and stimulating new markets and pathways through exchange, reciprocity, and hospitality.',
    links: [{ url: 'https://apam.org.au/', type: 'website' }],
    tags: [],
    projects: [],
  },
  {
    id: 48,
    type: 'organisation',
    name: 'PlayReactive',
    description:
      'PlayReactive is a play-making collective based in Melbourne, Australia. We create bold interactive experiences, from videogames to installation art.',
    links: [{ url: 'http://playreactive.com', type: 'website' }],
    tags: [],
    projects: [11],
  },
  {
    id: 49,
    type: 'organisation',
    name: 'Arts Centre Melbourne',
    description:
      'Arts House produces and presents a year-round program of contemporary art and performance. We create space for audiences and communities to gather, discuss and experience new ideas, new people and new worlds. Working purposefully to support independent artists and the small-medium sector, we believe the arts are for everyone.',
    links: [{ url: 'https://www.artshouse.com.au/', type: 'website' }],
    tags: [],
    projects: [],
  },
  {
    id: 50,
    type: 'organisation',
    name: 'State Library Victoria',
    description:
      "Established in 1854 as the Melbourne Public Library, State Library Victoria is Australia's oldest public library and one of the first free public libraries in the world. We want the Library to be a place for all in a changing world and to inspire possibilities.",
    links: [{ url: 'https://www.slv.vic.gov.au/', type: 'website' }],
    tags: [],
    projects: [],
  },
  {
    id: 51,
    type: 'organisation',
    name: 'TippingPoint Australia',
    description:
      'TippingPoint Australia is a collective of practising artists with extensive experience in all facets of arts production and management, supported by a high-profile and active Board.',
    links: [{ url: 'https://www.tippingpointaustralia.com/', type: 'website' }],
    tags: [],
    projects: [20, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44],
  },
  {
    id: 52,
    type: 'organisation',
    name: 'Creative Victoria',
    description:
      "Championing and supporting the state's creative industries, spanning arts, culture, screen, design and more.",
    links: [{ url: 'https://creative.vic.gov.au/', type: 'website' }],
    tags: [],
    projects: [19],
  },
  {
    id: 53,
    type: 'organisation',
    name: 'Centre for Reworlding',
    description:
      'Grounded in First Nations knowledge systems and protocols, the Centre for Reworlding is a brave 100-year project focused on intergenerational justice and building creative resilience.',
    links: [{ url: 'https://www.centreforreworlding.com/', type: 'website' }],
    tags: [],
    projects: [11, 45],
  },
  {
    id: 54,
    type: 'organisation',
    name: 'Green Music Australia',
    description:
      'Our role is to organise, facilitate and inspire musicians and the broader industry to make changes to improve our environmental performance.',
    links: [{ url: 'https://www.greenmusic.org.au/', type: 'website' }],
    tags: [],
    projects: [18, 67, 66, 64],
  },
  {
    id: 55,
    type: 'organisation',
    name: 'A Climate for Art',
    description:
      'A Climate for Art is a growing union of arts organisations and workers committing to responding to the climate crisis through tangible action.',
    links: [{ url: 'https://www.aclimateforart.com.au/', type: 'website' }],
    tags: [],
    projects: [68],
  },
  {
    id: 56,
    type: 'organisation',
    name: 'pvi collective',
    description:
      'pvi collective is a tactical media art group who create agitational participatory artworks intent on the creative disruption of everyday life.',
    links: [{ url: 'https://pvicollective.com/', type: 'website' }],
    tags: [],
    projects: [25, 26, 27, 28, 29, 102, 103, 105, 107],
  },
  {
    id: 57,
    type: 'organisation',
    name: 'Arts House',
    description:
      'Arts House produces and presents a year-round program of contemporary art and performance. We create space for audiences and communities to gather, discuss and experience new ideas, new people and new worlds. Working purposefully to support independent artists and the small-medium sector, we believe the arts are for everyone. ',
    links: [{ url: 'https://www.artshouse.com.au/', type: 'website' }],
    tags: [],
    projects: [20, 17],
  },
  {
    id: 58,
    type: 'organisation',
    name: 'Fair Share Fare',
    description:
      'Fair Share Fare was established in 2016 by artists Jen Rae and Dawn Weleski (co-founder of Conflict Kitchen) to explore the future of food in the context of the climate emergency through research, collaborative and multi-platform creative works, and community projects. Projects became data generators, solutionary sparks, and helped form communities around a problem. They aimed to dispel myths and increase literacy around food justice, help decolonize thinking around food provenance while advocating for food and land sovereignty, activate skills and knowledge sharing around food systems to support future food security, and reimagine a food system that should have been and still could be. Since 2020, Fair Share Fare has been working collaboratively with the Open Food Network Australia on co-designing food systems with communities in Moreland and Nillumbik.',
    links: [{ url: 'https://www.fairsharefare.com/', type: 'website' }],
    tags: [],
    projects: [59, 72, 73],
  },
  {
    id: 59,
    type: 'project',
    name: 'Future Proof Survival Guide',
    date: '2017',
    description:
      'What do you know, that you don’t know you know, that we all might need to know in a disaster? The Future Proof Survival Guide is about uncovering collective ‘know-how’ in the context of preparedness. In an age of increasing uncertainty and complexity, as the long emergency of climate change continues, what are the pieces of knowledge and skills at the thresholds of being lost; who holds that information; and who needs to know about it? ',
    links: [],
    tags: [],
  },
  {
    id: 60,
    type: 'project',
    name: 'Child of Now',
    date: '2018, 2021, 2022',
    description:
      'A massively co-authored story about the next century and the most ambitious portrait of a place’s people ever created. This project-in-development combines emerging virtual holographic and artificial intelligence technology with performances from 14,400 Victorians. ',
    links: [
      {
        url: 'https://robertwalton.net/project/child-of-now/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 61,
    type: 'project',
    name: 'Ready Steady Go',
    date: '2020',
    description:
      'Imagine the unimaginable. How prepared are you and your community in the event of a major disaster event? What if you call 000 and no one came for 48-72 hours?  Ready, Steady, Go is an online practical guide and repository of what ifs and what fors in case of emergency. Centred around the ‘emergency kit’, you’ll find fun and not-so-fun facts, questions, prompts, tips and hacks to imagine, prepare and plan in case of emergency.',
    links: [
      { url: 'https://www.jenraeis.com/ready-steady-go', type: 'website' },
    ],
    tags: [],
  },
  {
    id: 62,
    type: 'project',
    name: 'Portage',
    date: '2019, 2020, 2021',
    description:
      'PORTAGE is a multi-platform project in four stages: Raft, Flotilla, Shelter2Camp and First Assembly of the Centre for Reworlding. It offers survival skill workshops that explore in tangible ways the themes of displacement, evacuation, mobilisation, and shelter, allowing the public a visceral experience to the potential upheavals wrought by climate catastrophe. The project seeks to unearth overlooked skills, knowledges and values that might offer salvation in the years ahead. Both timely and willing to take the long view, PORTAGE is a call to mobilise, collaborate and arrive at purpose.',
    links: [
      {
        url: 'https://www.jenraeis.com/portage-raft-flotilla-shelter2camp',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 63,
    type: 'person',
    name: 'Matt Wicking',
    location: 'Wurundjeri Woi Wurrung Country',
    pronouns: 'he, they',
    description:
      'An experienced speaker, facilitator and change-maker. I help progressive groups amplify their impact.',
    links: [
      {
        url: 'https://cloudcatcher.org/',
        type: 'website',
      },
    ],
    tags: [],
    roles: [
      {
        targetId: 69,
        role: 'Member',
      },
      {
        targetId: 51,
        role: 'Board Member',
        startDate: '2015',
      },
      {
        targetId: 70,
        role: 'Lead Facilitator',
        startDate: '2013',
        endDate: '2018',
      },
      {
        targetId: 57,
        role: 'Greenie-in-Residence',
        startDate: '2013',
        endDate: '2015',
      },
      {
        targetId: 71,
        role: 'Communication Advisor',
        startDate: '2013',
        endDate: '2014',
      },
    ],
  },
  {
    id: 64,
    type: 'project',
    name: 'No Music on a Dead Planet',
    date: '2022',
    description:
      'Over three months in 2022, we ran our biggest climate campaign ever – No Music On A Dead Planet – bringing popular artists, record labels, festivals and venues together in a united call ahead of the federal election to implore fans to ‘vote for the planet’. Working closely with our friends at Music Declares Emergency, we galvanised the music community to advocate for strong climate action and created change at the ballot box and beyond.',
    links: [
      {
        url: 'https://www.greenmusic.org.au/nomusic',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 65,
    type: 'project',
    name: 'Resurgence',
    date: '2022',
    description:
      'In 2022, the Centre for Reworlding, in partnership with Incinerator Gallery, presented Resurgence—a multi artform exhibition re-telling the story of the moment when one world ends for another to begin. Resurgence draws from and expands upon our award-winning speculative futurist video work Refugium and previous collaborations with artists including Syrus Marcus-Ware, Marco Cher-Gibard and Aunty Vicki Waine Kinai.',
    links: [],
    tags: [],
  },
  {
    id: 66,
    type: 'project',
    name: 'BYOBottle',
    date: '2015',
    description:
      'We commit to help reduce plastic waste in the music industry by traveling with reusable water bottles, including BYOBottle language in our rider asking venues to provide water refill stations, and sharing out the BYOBottle message to fans.',
    links: [
      { url: 'https://www.greenmusic.org.au/byobottle', type: 'website' },
    ],
    tags: [],
  },
  {
    id: 67,
    type: 'project',
    name: 'AMIDESI',
    date: '2015',
    description:
      'Australia’s first sector-wide technological platform designed to analyse and report on sustainability data for the Australian Music Industry. AMIDESI will promote transparency, and enable the sector to gain knowledge of its historical and current environmental and social impact, understand key trends and challenges and identify improvement pathways. Green Music Australia’s aim is to unite the industry around shared goals and help our sector set ambitious targets in line with global climate change commitments',
    links: [{ url: 'https://www.greenmusic.org.au/amidesi', type: 'website' }],
    tags: [],
  },
  {
    id: 68,
    type: 'project',
    name: 'Divestment Toolkit',
    date: '',
    description:
      'Our divestment toolkit and resources are available to make your critical path to divestment easier.',
    links: [
      { url: 'https://www.aclimateforart.com.au/resources', type: 'website' },
    ],
    tags: [],
  },
  {
    id: 69,
    type: 'organisation',
    name: 'Climate Action Services',
    description:
      'Climate Action Services is an artist-centred consortium of groups and individuals, all with significant track records working at the intersections of art and climate response. The consortium’s vision is for a future where our nation’s culture fosters ecologically regenerative lives and practices. Our work ensures that climate action and Caring for Country are the foundational methodologies of all activity within the cultural sector - inspiring individuals, organisations and communities across the continent to be caretakers of place and planet. Together the consortium will develop much-needed critical thinking, emergent and experimental practices and narratives that move beyond unreachable ‘sustainability’ as a goal, to the capacities we will need to adapt to the constant systemic disruptions that are a certainty for the next century and beyond.',
    links: [],
    tags: [],
  },
  {
    id: 70,
    type: 'organisation',
    name: 'Centre for Sustainability Leadership',
    description:
      'Centre for Sustainability Leadership (2004 to 2018) created life-changing programs for individuals and organisations. Our flagship program being the Future Makers Fellowship. We offered regional programs, online leadership learning and one-on-one coaching.',
    links: [
      {
        url: 'https://www.linkedin.com/school/centre-for-sustainability-leadership',
        type: 'linkedin',
      },
    ],
    tags: [],
  },
  {
    id: 71,
    type: 'organisation',
    name: 'Bureau of Meteorology',
    description:
      'The Bureau of Meteorology is Australia’s national weather, climate and water agency. Through regular forecasts, warnings, monitoring and advice spanning the Australian region and Antarctic territory, we provide one of the most fundamental and widely used services of government. Our work helps people to deal with the harsh realities of their natural environment, including drought, floods, fires, storms, tsunami and tropical cyclones. We employ around 1,500 staff, and provide surveillance, forecasts and warning services 24 hours a day, every day of the year. We build and maintain the national observational infrastructure and provide the information and communication technology, research and corporate support needed to deliver these essential services to the Australian community. Our workforce is geographically dispersed, with more than 60 workplaces across Australia in capital cities, regional and remote locations as well as offshore islands and Antarctica. In an organisation as diverse as the Bureau there are excellent prospects for building an enjoyable and exciting career.',
    links: [],
    tags: [],
  },
  {
    id: 72,
    type: 'project',
    name: 'Apitherapy Quarantine',
    date: '2018',
    description:
      'Honey bees are dying worldwide as the result of a human-caused pandemic. Apitherapy Quarantine explores ideas of paranoia, risk, health and biosecurity – and the tensions between – via an immersive spa-meets-science-lab-meets-nature-reserve environment in collaboration with Art House’s resident colony of bees. Apitherapy Quarantine will provide isolated ‘treatments’ to small groups, but be prepared to confess your sins, secrets and desires in trade, or be left behind.',
    links: [
      {
        url: 'https://www.artshouse.com.au/events/apitherapy-quarantine/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 73,
    type: 'project',
    name: '3 Meals to Anarchy or Revolution',
    date: '2017',
    description:
      'Food brings us together, but it also has the capacity in times of turbulence to force us apart.  The 3 Meals to Anarchy or Revolution Supper Club explores food futures scenarios through a guided meal that could result in anarchy or a revolution. In 1906, commenting on social unrest, Alfred Henry Lewis stated in Cosmopolitan magazine that “there are only nine meals between mankind and anarchy”. In a world with increasing ecological degradation and greater disparities between the rich and poor, food security is of increasing global concern. What does that mean for Australia? What are some of the actions we can take today to secure food in the future for ourselves and for others? Can disruptions like disasters breed innovative thinking and action or are we really 9 meals away chaos? Three guests led conversations providing a global, local and Indigenous perspective to these questions by dispelling some food myths and offering provocations to chew on. This special Supper Club took place in the lead up to Refuge, where Arts House was transformed into an emergency relief centre, rehearsing disaster response in a heatwave scenario. Each of the courses were designed in consultation with the guests to be a provocation related to food ethics (see the menu for details). On the night, participants were advised: Food, refreshments and shelter will be experienced, but be prepared to beg, barter and trade to complete your meal.',
    links: [
      {
        url: 'https://www.fairsharefare.com/refuge-2017-heatwave',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 74,
    type: 'organisation',
    name: 'Trocadero Projects',
    description:
      'Trocadero Projects is an artist-run initiative presenting a diverse and experimental program of exhibitions and events. A non-profit art space run by volunteers, Trocadero strives to provide a safe and engaging environment for emerging artists to develop and exhibit in, while contributing to the local community.',
    links: [
      {
        url: 'https://trocaderoprojects.org.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 75,
    type: 'organisation',
    name: 'Theatre Network Australia',
    description:
      'TNA is the leading industry development and advocacy organisation for small to medium and independent performing arts, working nationally, with a dedicated Victorian program. TNA services a wide range of performing arts including dance, circus and live art. TNA strengthens artists and arts organisations, influences cultural policy at three levels of government, facilitates critical debate, and advocates for a more robust, interconnected, and innovative sector.',
    links: [
      {
        url: 'https://tna.org.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 76,
    type: 'organisation',
    name: 'Seventh Gallery',
    description:
      'We are a non-profit gallery mostly run by artists, for artists. Seventh functions with a paid Director and Community Manager and is supported by a volunteer Board of Directors, made of curators, writers, and people who labour in the arts, and creatives from other industries such as law, in our Board. We actively support these vocations across our program. Our exhibitions are free to attend Wednesday-Saturday 12pm-6pm and without appointment. ',
    links: [
      {
        url: 'https://www.seventhgallery.org/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 77,
    type: 'organisation',
    name: 'Schoolhouse Studios',
    description:
      'Schoolhouse Studios is a not-for-profit arts organisation based in Melbourne. Established in 2012, we are a vibrant arts organisation providing affordable studio space, event spaces, a gallery, and a bar for local artists and the wider community. We support emerging and mid-career artists, and are committed to fostering community engagement and diversity within the arts sector.',
    links: [
      {
        url: 'https://schoolhousestudios.com.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 78,
    type: 'organisation',
    name: 'Rebus Theatre',
    description:
      'Rebus Theatre creates social change through powerful, inclusive theatre. We run programs, create original performances and deliver theatre-based workplace training.',
    links: [
      {
        url: 'https://rebustheatre.com/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 79,
    type: 'organisation',
    name: 'Platform Arts',
    description:
      'Platform Arts, based on Wadawurrung Country in Djilang/Geelong, focuses on the development of artistic practice and ideas, leading to the presentation of these ideas as contemporary arts experiences. We curate a multi-artform program of exhibitions, performances, publications and events that respond to themes and provocations we believe are urgent for our times. ',
    links: [
      {
        url: 'https://platformarts.org.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 80,
    type: 'organisation',
    name: 'Outloud',
    description:
      'Outloud is based on Darug land in Bankstown, Western Sydney, one of Sydney’s most culturally diverse communities. We run a wide range of initiatives that support young people in their creative development, from school through to the launch of their careers and beyond. Our focus is to help young people, from a wide range of backgrounds, to find their voice.',
    links: [
      {
        url: 'https://outloud.org.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 81,
    type: 'organisation',
    name: 'Next Wave',
    description:
      'Our vision is a world shaped by artists. For forty years, Next Wave has shaped the Australian arts landscape, playing a pivotal role empowering and elevating early-career practitioners, and advocating for the next generation of artists. Next Wave began as a Narrm/Melbourne-based festival, platforming the next generation of artists to showcase contemporary, experimental, and bold new work.',
    links: [
      {
        url: 'https://nextwave.org.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 82,
    type: 'organisation',
    name: 'Multicultural Arts Victoria',
    description:
      'MAV (Multicultural Arts Victoria) is a non-profit community arts organisation with a profound commitment to arts and artists shaping the narratives that define who we are as a multicultural society.',
    links: [
      {
        url: 'https://www.mav.org.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 83,
    type: 'organisation',
    name: 'Linden New Art',
    description:
      'Linden New Art, established in 1986, is a public gallery located in the Melbourne suburb of St Kilda. Our vision is to be a leading mid-size contemporary art space. Our mission is to be a conversation-driven organisation championing bold new ideas. With a focus on mid-career artists, we pursue inclusive, thought-provoking programs creating new experiences & engagement through contemporary art. We embrace different ways of experiencing and seeing the world and oneself, and provide opportunities to learn, debate and explore through contemporary art. Our core activity is defined by three aspects: exhibitions, public and education programs, and knowledge generation. Exhibitions are uniquely focussed on supporting mid-career artists and consist of solo exhibitions, often of new work, and thematic exhibition, which engage with the big issues of our time.  Exhibitions are supported by multi-faceted public and educational programs, and the generation of online essays and exhibitions publications, targeted towards diverse audiences.',
    links: [
      {
        url: 'https://www.lindenarts.org/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 84,
    type: 'organisation',
    name: 'GUTS Dance Central Australia',
    description:
      'GUTS Dance Central Australia is an Mparntwe (Alice Springs) based contemporary dance organisation and is the only platform for dance investigation, creation, training and performance within a 1500km radius. GUTS is a responsive platform for dance practice, performance and engagement, situated in remote Central Australia. Reflecting our place and community, we add value and diversity to cultural expression locally and globally.',
    links: [
      {
        url: 'https://www.gutsdance.org.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 85,
    type: 'organisation',
    name: 'Footscray Community Arts',
    description:
      'Footscray Community Arts is a 50-year strong independent arts precinct dedicated to sharing stories. Our vision is that all communities are valued as makers of culture.',
    links: [
      {
        url: 'https://footscrayarts.com/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 86,
    type: 'organisation',
    name: 'Climarte',
    description:
      'CLIMARTE is an initiator, an educator and a catalyst for artists and arts organisations to engage in climate-focussed and socially engaged exhibitions and events. Established in 2010, CLIMARTE works to harness the creative power of the arts to inform, engage and inspire action towards a fairer, zero emissions future. Underpinned by climate science, it seeks, through the arts, to amplify and mobilise public responses to the climate crisis.',
    links: [
      {
        url: 'https://climarte.org/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 87,
    type: 'organisation',
    name: 'Chamber Made',
    description:
      'Based on Wurundjeri Country in Naarm/Melbourne, Chamber Made cultivates adventurous multi-artform practice to disrupt and rewrite conventions of live performance. A commitment to collaboration, an appetite for innovation, and an ongoing fascination in the intersecting spaces between artforms drives us into our fourth decade with renewed energy. Our approach is genre-agnostic, with each work reflecting the diverse practices of the collaborating artists in a form that emerges in relationship to the concept and themes being explored. Our works speak directly to a 21st century Australia enriched by the meeting of cultures.',
    links: [
      {
        url: 'https://chambermade.org/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 88,
    type: 'organisation',
    name: 'Bus Projects',
    description:
      'Bus Projects is an artist-run organisation dedicated to supporting the critical, conceptual and interdisciplinary practices of Australian artists. In addition to its core gallery-based program of exhibitions, events and residencies, Bus Projects collaborates with a range of artists and arts organisations to produce projects off-site and within the public realm.',
    links: [
      {
        url: 'https://busprojects.org.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 89,
    type: 'organisation',
    name: 'Bureau of Works',
    description:
      'Bureau of Works is an independent producing company founded by Erin Milne. We are focused on creating strategic opportunities for artists; supporting artists in the creation of new work and exploratory form; and developing contexts that foster risk-taking and ambition. We live and work on Boon Wurrung country, on the lands of the Kulin Nation, in the western suburbs of Melbourne, Australia. We’re interested in projects that encompass radical empathy, collaborative teams, good humour and a sense of adventure. We enjoy working closely with artists to realise the environment, resources and partners they need to achieve their best work. We also work with organisations to do the same thing, in different contexts. Our services include: producing, market development, event curation, strategic planning, facilitation, project management, research, and grant writing.',
    links: [
      {
        url: 'https://bureauofworks.com.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 90,
    type: 'organisation',
    name: 'Auspicious Arts Projects',
    description:
      'Auspicious Arts Projects is a not-for-profit creative community management organisation. We provide independent artists with a secure and accessible framework to assist them with creative developments and producing work. Working with Auspicious means that artists have the benefits and financial protection of working with a larger organisation, while remaining independent and in creative control. With an open door policy and over 30 years of industry experience, we’ve worked with projects across the complete independent arts spectrum. We sweat the small (and big) stuff so you don’t have to. ',
    links: [
      {
        url: 'https://www.auspicious.com.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 91,
    type: 'organisation',
    name: 'Arts on Tour',
    description:
      'Arts on Tour is one of Australia’s most prolific performing arts touring organisations and the peak body for touring in NSW. Our purpose is to sustainably bring culturally distinctive and resonant arts experiences to audiences and communities across the country, whilst leading on best practice and building the touring capacity of the performing arts sector as a whole.',
    links: [
      {
        url: 'https://artsontour.com.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 92,
    type: 'organisation',
    name: 'Aphids',
    description:
      'APHIDS is an artist-led experimental art organisation based in Naarm/Melbourne. Collaborative, risk-taking and future focused, APHIDS is led by Lara Thoms and Mish Grigor. APHIDS makes experimental work across artforms and contexts: in public space, festivals, digital platforms, across visual and performing arts, major institutions and beyond. APHIDS have been presented in every state and territory in Australia, from the Malthouse Theatre to the Art Gallery of SA. We have engaged audiences in samurai hotels in Japan, churches in Finland, community halls in regional towns, drive-ins and even beamed art into outer space.',
    links: [
      {
        url: 'https://aphids.net/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 93,
    type: 'organisation',
    name: 'Market Forces',
    description:
      'Market Forces believes that the banks, superannuation funds and governments that have custody of our money should use it to protect not damage our environment. Our work exposes the institutions that are financing environmentally destructive projects and helps Australians hold these institutions accountable. We work with the community to prevent investment in projects that would harm the environment and drive global warming.',
    links: [
      {
        url: 'https://www.marketforces.org.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 94,
    type: 'organisation',
    name: '350.org',
    description:
      'We’re an international movement of ordinary people working to end the age of fossil fuels and build a world of community-centered renewable energy for all. We are calling for a deeper transition, one which places energy justice at the beating heart of its values. We are moving forward into the world we want to see, leaving no one behind. And we are doing this with the urgency the climate crisis demands of us.',
    links: [
      {
        url: 'https://350.org/',
        type: 'website',
      },
    ],
    tags: [],
    projects: [102],
  },
  {
    id: 95,
    type: 'organisation',
    name: 'Sunrise Project',
    description:
      'Driven by the imperative of climate justice, The Sunrise Project’s mission is to scale social movements to drive the transition from fossil fuels to renewable energy as fast as possible. The Sunrise Project is a network of changemakers who believe in the power of social movements to change the world. From ending slavery to civil rights to women’s suffrage: social movements have achieved impossible things to make the world a better place. Now, more than ever, we need to nurture and scale powerful movements to solve the climate crisis. Sitting at the intersection of social movements and philanthropy, we’ve developed a ‘directed network’ model that supports networks of people and organisations to work together to achieve large-scale change that wouldn’t have been possible by individual organisations acting alone.',
    links: [
      {
        url: 'https://sunriseproject.org/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 96,
    type: 'organisation',
    name: 'West Space',
    description:
      'West Space is the leading independent contemporary visual arts organisation in Naarm/Melbourne’s inner-north. West Space supports the next generation of artists in Australia, and builds communities around art and ideas.',
    links: [
      {
        url: 'https://westspace.org.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 97,
    type: 'project',
    name: 'Greening the Arts',
    description:
      'A set of principles for Greening the Arts that emerged through the 2014 Greenie-in-Residence program at Arts House. Melbourne-based theatre artists and arts organisations participated in the year-long program of workshops, consultation and networking events run by Greenie, Matt Wicking. It saw them incorporating environmental sustainability into the making, administration and delivery of their work.',
    links: [
      {
        url: 'https://issuu.com/mattwicking/docs/greeningthearts',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 98,
    type: 'project',
    name: 'Sound Country Artist Leadership Retreat',
    description:
      'Sound Country Artist Climate Leadership Retreat. This program is designed for musicians and songwriters to develop your skills, self-reflection, support and networks to take the next steps in using your voice for a better future.',
    links: [
      {
        url: 'https://www.greenmusic.org.au/artist-retreat-2024',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 99,
    type: 'project',
    name: 'Camping Buddy',
    description:
      'Camping Buddy is a new web tool created by Green Music Australia in partnership with Queensland University of Technology. In 2020, the two organisations accepted a 4-year research and IT creation project grant from the NSW Environmental Trust to undertake a challenge, “How can we use technology to battle the nationwide problem of festival campsite litter?”',
    links: [
      {
        url: 'https://www.campingbuddy.com.au/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 100,
    type: 'project',
    name: 'Party with the Planet',
    description:
      'We’ve been trashing our festivals for far too long - but we have the power to change.Led by Green Music Australia, Party with the Planet is an alliance of major music festivals, promoters, social enterprises, and nonprofits aiming to tackle the enormous piles of waste left behind at festival campsites each year. Research conducted over the past two years found over 50% of waste generated at music festivals is campsite litter. Broken tents were the primary cause of littering in the campsite, facilitated by a lack of respect and disregard for waste reduction. We’ve got to do better!  That’s why we’re reaching out to all music lovers this year and asking them to join the movement, by keeping their campsite clean, and taking everything home. ',
    links: [
      {
        url: 'https://www.partywiththeplanet.org/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 101,
    type: 'project',
    name: 'Green Action Program',
    description:
      'The Green Action Program (GAP) supports music businesses to undertake a range of facilitated learning modules to help them better understand, monitor and improve their environmental impacts. ',
    links: [
      {
        url: 'https://www.greenmusic.org.au/gap',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 102,
    type: 'project',
    name: 'Bad Romance: Coal, gas, and oil sponsorship in the Australian arts industry',
    description:
      'Artists and climate activists are calling for an end to the “bad romance” between arts organisations and gas companies, as a new report from Swinburne University of Technology researchers, launching on Thursday August 10, for the first time quantifies fossil fuel company partnerships with the arts. The report, Bad Romance: Coal, gas, and oil sponsorship in the Australian arts industry, was commissioned by climate activist group 350 Australia',
    links: [
      {
        url: 'https://350.org.au/bad-romance-coal-gas-and-oil-sponsorship-in-the-australian-arts-industry/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 103,
    type: 'project',
    name: '#CallTime Campaign',
    description:
      'From flooded festivals, to concerts cancelled by bushfire threats and unsafe heat disrupting play at the tennis, footy and more, climate change is wreaking havoc on the activities and events we love. Meanwhile, polluting fossil fuel giants are using these events to buy community goodwill and distract from the planet-wrecking harm they cause Can you urge Australia’s prominent sports and arts institutions to #CallTime on fossil fuel sponsorship today, by taking the Fossil Fuel Free Sponsorship Pledge? The Climate Council has developed a fossil fuel free sponsorship code, Calling Time: How to Remove Fossil Fuel Sponsorships from Sports, Arts and Events, that details how planet-wrecking companies like Woodside, Santos, Chevron and Tamboran Resources are piggy-backing on Australia’s most loved and iconic pastimes to distract from their environmentally destructive impacts.',
    links: [
      {
        url: 'https://www.climatecouncil.org.au/action-call-time-on-fossil-fuel-sponsorships/',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 104,
    type: 'organisation',
    name: 'Climate Council',
    description:
      'Climate Council is Australia’s own independent, evidence-based organisation on climate science, impacts and solutions. We connect decision-makers, the public and the media to catalyse action at scale, elevate climate stories in the news and shape the conversation on climate consequences and action, at home and abroad. We advocate for climate policies and solutions that can rapidly drive down emissions, based on the most up-to-date climate science and information. We do this in partnership with our incredible community: thousands of generous, passionate supporters and donors, who have backed us every step of the way since they crowd-funded our beginning as a non-profit organisation in 2013.',
    links: [
      {
        url: 'https://www.climatecouncil.org.au/',
        type: 'website',
      },
    ],
    tags: [],
    projects: [103],
  },
  {
    id: 105,
    type: 'project',
    name: 'Climate Champions',
    description:
      'Climate change is daunting to face alone, but there are many inspiring stories of people like you taking steps to solve climate change across Australian society. Every day we see more businesses, organisations and governments coming together to take concrete steps towards a zero emissions future.  Our climate champions are showcasing their commitments and actions to inspire other Australians to act on climate change and realise zero emissions opportunities. Read their stories below. By working together, we can harness the opportunities from investment in zero emissions industries, including renovating and creating zero emissions buildings, transport, cities and regions; electrifying and innovating manufacturing; regenerative agriculture; carbon neutral food production; land restoration and biodiversity protection; and creating sustainable jobs to build better futures for all Australians. We can also seize the opportunity to become a clean energy superpower, via direct renewable energy exports and in jobs rich industries like green steel, cement, aluminium and hydrogen.',
    links: [
      {
        url: 'https://www.betterfutures.org.au/champions',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 106,
    type: 'organisation',
    name: 'Better Futures',
    description:
      "Better Futures Australia's network of partners are amplifying climate work already underway by bringing together public and private sector leaders to scale success stories and demonstrate Australia's readiness for an ambitious national response to climate change. Together, we are building local cross-sectoral networks, showcasing individual and collaborative climate action, and inviting all Australians to join a growing community of climate champions in advocating for a national response that will reach zero emissions by 2050 or sooner.",
    links: [
      {
        url: 'https://www.betterfutures.org.au/',
        type: 'website',
      },
    ],
    tags: [],
    projects: [105],
  },
  {
    id: 107,
    type: 'project',
    name: 'Petrocultures 2024: Perth',
    description:
      'With Petrocultures 2024, the biennial conference series comes for the first time to the southern hemisphere. This conference explores distances, struggles, visions, dynamics, flows, regulations, and resistances in the reproduction of petrocultures. The conference provides a platform for voices of the South—from Western Australia, the country’s eastern states, the Indian Ocean Rim Region and beyond—to discuss energy futures, their problematic pasts, and what inclusive and just trajectories toward transitions to renewables may look like. This platform will allow us to merge scholarly contestations of possible and desirable energy futures with imaginative interventions from the creative arts, community activists, and First Nations’ yarns to prefigure more equitable and liveable energy outlooks.',
    links: [
      {
        url: 'https://www.petrocultures2024.com/perth.html',
        type: 'website',
      },
    ],
    tags: [],
  },
  {
    id: 108,
    type: 'organisation',
    name: 'WA Climate Leaders',
    description:
      'We are a vibrant non-partisan, cross-sectoral network driving efforts to phase out greenhouse gas emissions while creating economic opportunity and a clean energy future for WA.  We welcome decision makers from business, industry, government, academia and civil society who are committed to setting ambitious goals and accelerating climate action in this state. Together, we will harness and mobilise WA’s ingenuity and abundant resources to be world leaders in the race to keep global warming to under 1.5 degrees.  WA Climate Leaders runs public conversations, forges important connections and provides science-backed information to support and accelerate this vision for WA.',
    links: [
      {
        url: 'https://www.waclimateleaders.org/',
        type: 'website',
      },
    ],
    tags: [],
    projects: [],
  },
  {
    id: 109,
    type: 'organisation',
    name: 'Curtin University',
    description:
      'Curtin University is an Australian public research university based in Bentley, Perth, Western Australia, with campuses in Australia, Malaysia, Singapore, Mauritius and Dubai. Curtin’s Energy Humanities Initiative will be largely responsible for the organisation, joined up with colleagues from the eastern States, and with Curtin’s Institute for Energy Transition as the primary on-campus sponsor. Across Australia and in the region, there is growing interest in (and urgent need for) the humanities, the social sciences, and the creative arts to engage in critical and imaginative scholarship and praxis. These are vital to address what the 2021 Australian Energy Transition Research Plan identifies as “a particularly critical and urgent gap in energy research in the humanities, arts and social sciences disciplines” (p.25). This is an ideal and overdue space for the energy and environmental humanities!',
    links: [
      {
        url: 'https://www.curtin.edu.au/',
        type: 'website',
      },
    ],
    tags: [],
    projects: [107],
  },
];

const nodeDetails = [
  ...peopleNodeDetails,
  ...projectNodeDetails,
  ...organisationNodeDetails,
];

const nodes: GraphNode[] = [
  ...peopleNodeDetails.map((person) => ({
    id: person.id,
    collapsed: false,
    childLinks: [],
    parentLinks: [],
    type: person.type,
  })),
  ...projectNodeDetails.map((project) => ({
    id: project.id,
    collapsed: false,
    childLinks: [],
    parentLinks: [],
    type: project.type,
  })),
  ...organisationNodeDetails.map((organisation) => ({
    id: organisation.id,
    collapsed: false,
    childLinks: [],
    parentLinks: [],
    type: organisation.type,
  })),
].sort((a, b) => a.id - b.id); // Sort by id

const links: GraphLink[] = [
  {
    source: 0, // Lana Nguyen
    target: 55, // A Climate for Art
  },
  {
    source: 1, // Berish Bilander
    target: 54, // Green Music Australia
  },
  {
    source: 1, // Berish Bilander
    target: 54, // Green Music Australia
  },
  {
    source: 5, // Harry Shang Lun Lee
    target: 53, // Co-instigator role
  },
  {
    source: 5, // Jen Rae
    target: 53, // Co-instigator role
  },
  {
    source: 5, // Angharad Wynne-Jones
    target: 17, // Artist role
  },
  {
    source: 5, // Catherine Jones
    target: 11, // Artist role
  },
  {
    source: 5, // Emma Bosworth
    target: 45, // Artist role
  },
  {
    source: 46,
    target: 33,
  },
  {
    source: 46,
    target: 11,
  },
  {
    source: 48,
    target: 11,
  },
  {
    source: 51,
    target: 20,
  },
  {
    source: 51,
    target: 30,
  },
  {
    source: 51,
    target: 31,
  },
  {
    source: 51,
    target: 32,
  },
  {
    source: 51,
    target: 33,
  },
  {
    source: 51,
    target: 34,
  },
  {
    source: 51,
    target: 35,
  },
  {
    source: 51,
    target: 36,
  },
  {
    source: 51,
    target: 37,
  },
  {
    source: 51,
    target: 38,
  },
  {
    source: 51,
    target: 39,
  },
  {
    source: 51,
    target: 40,
  },
  {
    source: 51,
    target: 41,
  },
  {
    source: 51,
    target: 42,
  },
  {
    source: 51,
    target: 43,
  },
  {
    source: 51,
    target: 44,
  },
  { source: 53, target: 11 },
  { source: 53, target: 45 },
  { source: 54, target: 18 },
  { source: 56, target: 25 },
  { source: 56, target: 26 },
  { source: 56, target: 27 },
  { source: 56, target: 28 },
  { source: 56, target: 29 },
  { source: 57, target: 20 },
  { source: 58, target: 59 },
  { source: 2, target: 55 },
  { source: 3, target: 53 }, // Co-instigator
  { source: 3, target: 60 }, // Writer
  { source: 3, target: 45 }, // Artist (2021)
  { source: 3, target: 11 }, // Artist (2023)
  { source: 3, target: 13 }, // Author (2021)
  { source: 3, target: 14 }, // Author (2022)
  { source: 3, target: 15 }, // Author (2019)
  { source: 3, target: 16 }, // Author (2017)
  { source: 5, target: 53 }, // Co-instigator (Jen Rae)
  { source: 5, target: 17 }, // Artist (2016)
  { source: 5, target: 17 }, // Artist (2021)
  { source: 4, target: 48 }, // Director
  { source: 4, target: 44 }, // Artist (2015)
  { source: 4, target: 21 }, // Artist (??)
  { source: 4, target: 22 }, // Designer (??)
  { source: 4, target: 23 }, // Designer (??)
  { source: 4, target: 24 }, // Organiser (??)
  { source: 4, target: 40 }, // Game Designer (2014)
  { source: 4, target: 17 }, // Consultant (2021)
  { source: 4, target: 17 }, // Artist (2016)
  { source: 4, target: 17 }, // Artist (2021)
  { source: 4, target: 11 }, // Artist (2023)
  { source: 9, target: 48 }, // Developer (2024)
  { source: 9, target: 11 }, // Developer (2024)
  {
    source: 5,
    target: 59,
  },
  {
    source: 5,
    target: 62,
  },
  {
    source: 5,
    target: 61,
  },
  {
    source: 5,
    target: 58,
  },
  {
    source: 5,
    target: 12,
  },
  { source: 6, target: 30 }, // Role (??)
  { source: 6, target: 31 }, // Role (??)
  { source: 6, target: 32 }, // Role (??)
  { source: 6, target: 33 }, // Role (??)
  { source: 6, target: 34 }, // Role (??)
  { source: 6, target: 35 }, // Role (??)
  { source: 6, target: 36 }, // Role (??)
  { source: 6, target: 37 }, // Role (??)
  { source: 6, target: 38 }, // Role (??)
  { source: 6, target: 39 }, // Role (??)
  { source: 6, target: 40 }, // Role (??)
  { source: 6, target: 41 }, // Role (??)
  { source: 6, target: 42 }, // Role (??)
  { source: 6, target: 43 }, // Role (??)
  { source: 6, target: 44 }, // Role (??)
  { source: 6, target: 49 }, // Artist (2021)
  { source: 6, target: 50 }, // Artist (2021)
  { source: 6, target: 51 }, // Artist (2021)
  { source: 6, target: 60 }, // Artist (2021)
  { source: 6, target: 20 }, // Artist (2021)
  { source: 7, target: 57 }, // Director (2018-2024)
  { source: 7, target: 47 }, // Director (2018-2024)
  { source: 8, target: 54 }, // Co-CEO (2018-2020)
  { source: 10, target: 25 },
  { source: 10, target: 26 },
  { source: 10, target: 27 },
  { source: 10, target: 28 },
  { source: 10, target: 29 },
  { source: 52, target: 19 },
  { source: 6, target: 57 },
  { source: 0, target: 69 },
  { source: 1, target: 69 },
  { source: 2, target: 69 },
  { source: 3, target: 69 },
  { source: 4, target: 69 },
  { source: 5, target: 69 },
  { source: 6, target: 69 },
  { source: 7, target: 69 },
  { source: 8, target: 69 },
  { source: 10, target: 69 },
  { source: 63, target: 69 },
  { source: 63, target: 51 },
  { source: 63, target: 57 },
  { source: 63, target: 71 },
  { source: 63, target: 70 },
  { source: 54, target: 67 },
  { source: 54, target: 66 },
  { source: 54, target: 64 },
  { source: 55, target: 68 },
  { source: 2, target: 19 },
  { source: 5, target: 65 },
  { source: 58, target: 72 },
  { source: 58, target: 73 },
  { source: 57, target: 17 },
  { source: 55, target: 74 }, // Role (??)
  { source: 55, target: 75 }, // Role (??)
  { source: 55, target: 76 }, // Role (??)
  { source: 55, target: 77 }, // Role (??)
  { source: 55, target: 78 }, // Role (??)
  { source: 55, target: 79 }, // Role (??)
  { source: 55, target: 80 }, // Role (??)
  { source: 55, target: 81 }, // Role (??)
  { source: 55, target: 82 }, // Role (??)
  { source: 55, target: 83 }, // Role (??)
  { source: 55, target: 84 }, // Role (??)
  { source: 55, target: 85 }, // Role (??)
  { source: 55, target: 86 }, // Role (??)
  { source: 55, target: 87 }, // Role (??)
  { source: 55, target: 88 }, // Role (??)
  { source: 55, target: 89 }, // Role (??)
  { source: 55, target: 90 }, // Role (??)
  { source: 55, target: 91 }, // Role (??)
  { source: 55, target: 92 }, // Role (??)
  { source: 55, target: 93 }, // Role (??)
  { source: 55, target: 94 }, // Role (??)
  { source: 55, target: 95 }, // Role (??)
  { source: 55, target: 96 }, // Role (??)
  { source: 7, target: 17 }, // Role (??)
  { source: 63, target: 97 }, // Role (??)
  { source: 63, target: 17 }, // Role (??)
  { source: 63, target: 44 }, // Role (??)
  { source: 63, target: 35 }, // Role (??)
  { source: 63, target: 43 }, // Role (??)
  { source: 63, target: 41 }, // Role (??)
  { source: 63, target: 20 }, // Role (??)
  { source: 63, target: 40 }, // Role (??)
  { source: 63, target: 42 }, // Role (??)
  { source: 63, target: 18 }, // Role (??)
  { source: 63, target: 54 }, // Consultant (2016-2024)
  { source: 63, target: 101 }, // Role (??)
  { source: 8, target: 101 }, // Role (??)
  { source: 8, target: 67 }, // Role (??)
  { source: 54, target: 101 }, // Role (??)
  { source: 54, target: 98 }, // Role (??)
  { source: 54, target: 99 }, // Role (??)
  { source: 54, target: 100 }, // Role (??)
  { source: 58, target: 62 }, // Role (??)
  { source: 5, target: 73 }, // Role (??)
  { source: 5, target: 72 }, // Role (??)
  { source: 49, target: 73 }, // Role (??)
  { source: 17, target: 72 }, // Role (??)
  { source: 17, target: 22 }, // Role (??)
  { source: 17, target: 62 }, // Role (??)
  { source: 58, target: 17 }, // Role (??)
  { source: 0, target: 19 }, // Role (??)
  { source: 56, target: 94 }, // Role (??)
  { source: 94, target: 102 }, // Role (??)
  { source: 56, target: 102 }, // Role (??)
  { source: 56, target: 103 }, // Role (??)
  { source: 104, target: 103 }, // Role (??)
  { source: 56, target: 105 }, // Role (??)
  { source: 106, target: 105 }, // Role (??)
  { source: 56, target: 107 }, // Role (??)
  { source: 56, target: 108 }, // Role (??)
  { source: 109, target: 107 }, // Role (??)
  { source: 5, target: 70 },
];

export default { nodeDetails, nodes, links };
