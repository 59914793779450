import React, { useRef, useEffect } from 'react';
import ForceGraph from 'force-graph';
import * as d3 from 'd3';

const rootId = 0;

// Random tree
const N = 300;
const gData = {
  nodes: [...Array(N).keys()].map((i) => ({
    id: i,
    collapsed: i !== rootId,
    childLinks: [],
  })),
  links: [...Array(N).keys()]
    .filter((id) => id)
    .map((id) => ({
      source: Math.round(Math.random() * (id - 1)),
      target: id,
    })),
};

// link parent/children
const nodesById = Object.fromEntries(
  gData.nodes.map((node) => [node.id, node])
);
gData.links.forEach((link) => {
  nodesById[link.source].childLinks.push(link);
});

const getPrunedTree = () => {
  const visibleNodes = [];
  const visibleLinks = [];

  (function traverseTree(node = nodesById[rootId]) {
    visibleNodes.push(node);
    if (node.collapsed) return;
    visibleLinks.push(...node.childLinks);
    node.childLinks
      .map((link) =>
        typeof link.target === 'object' ? link.target : nodesById[link.target]
      ) // get child node
      .forEach(traverseTree);
  })(); // IIFE

  return { nodes: visibleNodes, links: visibleLinks };
};

const SimpleGraph = () => {
  const containerRef = useRef(null);
  const graphInstance = useRef(null);

  useEffect(() => {
    // Initialize the graph
    graphInstance.current = ForceGraph()(containerRef.current)
      .graphData(getPrunedTree())
      .onNodeClick((node) => {
        if (node.childLinks.length) {
          node.collapsed = !node.collapsed; // toggle collapse state
          graphInstance.current.graphData(getPrunedTree());
        }
      })
      .linkDirectionalParticles(1)
      .linkDirectionalParticleWidth(2.5)
      .nodeColor((node) =>
        !node.childLinks.length ? 'green' : node.collapsed ? 'red' : 'yellow'
      );

    // Cleanup on unmount
    return () => {
      if (graphInstance.current) {
        graphInstance.current._destructor && graphInstance.current._destructor();
      }
    };
  }, []);

  return <div ref={containerRef} style={{ width: '100%', height: '100vh' }} />;
};

export default SimpleGraph;
