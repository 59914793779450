import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import SimpleGraph from './Simple.tsx';
import './styles.css';
import Fern from './Fern.jsx';
import ForceGraphWrapper from './ForceGraphWrapper.tsx';
import logo from './bilyalogo.png';
import ForceGraph from 'force-graph';
import AboutGraph from './AboutGraph.tsx';

function App() {
  const [showAcknowledgment, setShowAcknowledgment] = useState(false);
  const [activeView, setActiveView] = useState('MAP'); // State to track active view

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      setShowAcknowledgment(true);
    }
  }, []);

  const handleEnter = () => {
    localStorage.setItem('hasVisited', 'true');
    setShowAcknowledgment(false);
  };

  const ResourcesTable = () => (
    <div>
      <div style={{ padding: '40px 20px', borderBottom: '1px solid black' }}>
        {['Opportunities', 'Events', 'Australia'].map((item, index) => (
          <span
            key={index}
            style={{
              backgroundColor: 'rgba(243, 249, 255, 0.5)',
              borderRadius: '30px',
              padding: '20px 40px',
              border: '1px solid #000000',
              fontFamily: 'IBM Plex Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 400,
              margin: '10px',
            }}
          >
            {item}
          </span>
        ))}
      </div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
          {[
            'Commission for Climate Art',
            'Creative Australia Grant',
            'National Climate Resilience Conference',
            'Climate Fellowship',
          ].map((item, index) => (
            <tr key={index} className="resources-row">
              <td style={{ padding: '30px 40px' }}>{item}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const AboutPage = () => {
    return (
      <div>
        {/* <div
          style={{
            padding: '20px 40px',

            fontFamily: 'IBM Plex Sans-Serif',
            fontSize: '20px',
            fontWeight: 400,
            margin: '10px',
          }}
        >
          BILYA was created on the unceded lands of the Wurrundjeri, Taungurung,
          Dja Dja Wurrung, Ngunnawal and Ngambri peoples.
        </div> */}
        <AboutGraph />
      </div>
    );
  };

  const renderActiveView = () => {
    switch (activeView) {
      case 'MAP':
        return <SimpleGraph />;
      case 'RESOURCES':
        return <ResourcesTable />; // Replace with actual component
      case 'ABOUT':
        return <AboutGraph />; // Replace with actual component
      default:
        return <SimpleGraph />;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
      }}
    >
      {showAcknowledgment && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              textAlign: 'left',
              padding: '20px',
              maxWidth: '900px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h1
              style={{
                fontFamily: 'IBM Plex Mono sans-serif',
                fontSize: '36px',
                fontWeight: 450,
                lineHeight: '46.8px',
                textAlign: 'left',
                width: '100%',
              }}
            >
              Acknowledgement of Country
            </h1>
            <p
              style={{
                fontFamily: 'IBM Plex Serif',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '30px',
                textAlign: 'left',
              }}
            >
              BILYA was created on the unceded lands of the Wurrundjeri,
              Taungurung, Dja Dja Wurrung, Ngunnawal and Ngambri peoples.
              <br />
              <br />
              The Centre for Reworlding pays our deepest respects to their
              ancestors and Elders past and present and acknowledge their
              enduring legacy of care for Country so we may enjoy the stars,
              drink from the waters and continue to learn from the land.
              <br />
              <br />
              We are committed to strengthening relationships between Aboriginal
              and non-Aboriginal peoples for the benefit of future generations
              to thrive in restored ecosystems. The Centre for Reworlding
              extends this acknowledgement to any other Aboriginal and Torres
              Strait Island people and to any other First Nations people who
              engage with our work - for it is our Elders and Kin who hold the
              key to healing Country, others’ ancestral lands and the world’s
              broken systems for the health and wellbeing of all our future
              ancestors.
            </p>
            <button
              onClick={handleEnter}
              style={{
                marginTop: '20px',
                padding: '10px 20px',
                fontFamily: 'IBM Plex Sans',
                fontSize: '32px',
                fontStyle: 'italic',
                fontWeight: 400,
                lineHeight: '41.6px',
                textAlign: 'center',
                cursor: 'pointer',
                width: 400,
                backgroundColor: '#47B2FF',
                color: '#fff',
                border: 'none', // Removed the border
                borderRadius: '50px',
              }}
            >
              Enter
            </button>
          </div>
        </div>
      )}
      <div
        style={{
          backgroundColor: 'white',
          color: 'black',
          textAlign: 'center',

          fontWeight: 'bold',
          borderBottom: '1px solid black',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '60px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', padding: '20px' }}>
          <img
            src={logo}
            alt="Logo"
            style={{ marginRight: '15px', width: '35px' }}
          />
          <span style={{ fontSize: '24px' }} className="title">
            BILYA
          </span>
        </div>
        <div style={{ heght: '60px' }}>
          <button className="button" onClick={() => setActiveView('MAP')}>
            MAP
          </button>
          <button className="button" onClick={() => setActiveView('RESOURCES')}>
            RESOURCES
          </button>
          <button className="button" onClick={() => setActiveView('ABOUT')}>
            ABOUT
          </button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
        }}
      >
        {/* <div
          style={{
            width: '250px',
            backgroundColor: '#f4f4f4',
            padding: '20px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <SimpleGraph />
        </div> */}
        <div
          style={{
            flex: 1,
            backgroundColor: '#ffffff',
            padding: '20px',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {renderActiveView()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
