import React from 'react';

const LoadingLogo = ({ width = 475, height = 475, scale = 1 }) => (
  <svg
    width={width * scale}
    height={height * scale}
    viewBox="0 0 475 475"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="237.491"
      cy="237.491"
      r="237.491"
      fill="black"
      fillOpacity="0.05"
    />
    <circle
      cx="237.49"
      cy="237.491"
      r="123.31"
      fill="black"
      fillOpacity="0.05"
      stroke="white"
      strokeWidth="25"
    />
    <path
      d="M122.769 331.334C70.941 331.334 28.9258 289.319 28.9258 237.49C28.9258 185.662 70.941 143.646 122.769 143.646C170.328 143.646 203.866 190.656 237.459 237.621C270.99 190.656 304.486 143.646 352.209 143.646C404.037 143.646 446.053 185.662 446.053 237.49C446.053 289.319 404.037 331.334 352.209 331.334C304.575 331.334 270.99 284.499 237.459 237.621C203.991 284.499 170.489 331.334 122.769 331.334Z"
      fill="black"
      fillOpacity="0.05"
    />
    <path
      d="M122.769 331.334C70.941 331.334 28.9258 289.319 28.9258 237.49C28.9258 185.662 70.941 143.646 122.769 143.646C217.798 143.646 256.851 331.334 352.209 331.334C404.037 331.334 446.053 289.319 446.053 237.49C446.053 185.662 404.037 143.646 352.209 143.646C256.851 143.646 218.297 331.334 122.769 331.334Z"
      stroke="white"
      strokeWidth="25"
    />
  </svg>
);

export default LoadingLogo;
