import image0 from '../images/0.png';
import image1 from '../images/1.png';
import image2 from '../images/2.png';
import image3 from '../images/3.png';
import image4 from '../images/4.png';
import image5 from '../images/5.png';
import image6 from '../images/6.png';
import image7 from '../images/7.png';
import image8 from '../images/8.png';
import image9 from '../images/9.png';
import image10 from '../images/10.png';
import image11 from '../images/11.png';
import image12 from '../images/12.png';
import image13 from '../images/13.png';
import image14 from '../images/14.png';
import image15 from '../images/15.png';
import image16 from '../images/16.png';
import image17 from '../images/17.png';
import image18 from '../images/18.png';
import image20 from '../images/20.png';
import image21 from '../images/21.png';
import image22 from '../images/22.png';
import image23 from '../images/23.png';
import image24 from '../images/24.png';
import image25 from '../images/25.png';
import image26 from '../images/26.png';
import image28 from '../images/28.png';
import image29 from '../images/29.png';
import image37 from '../images/37.png';
import image44 from '../images/44.png';
import image45 from '../images/45.png';
import image46 from '../images/46.png';
import image47 from '../images/47.png';
import image48 from '../images/48.png';
import image49 from '../images/49.png';
import image50 from '../images/50.png';
import image51 from '../images/51.png';
import image52 from '../images/52.png';
import image53 from '../images/53.png';
import image54 from '../images/54.png';
import image55 from '../images/55.png';
import image56 from '../images/56.png';
import image57 from '../images/57.png';
import image58 from '../images/58.png';
import image59 from '../images/59.png';
import image60 from '../images/60.png';
import image63 from '../images/63.png';
import image65 from '../images/65.png';
import image67 from '../images/67.png';
import image69 from '../images/69.png';
import image70 from '../images/70.png';
import image71 from '../images/71.png';
import image72 from '../images/72.png';

const ImageMap: Record<number, string> = {
  0: image0,
  1: image1,
  2: image2,
  3: image3,
  4: image4,
  5: image5,
  6: image6,
  7: image7,
  8: image8,
  9: image9,
  10: image10,
  11: image11,
  12: image12,
  13: image13,
  14: image14,
  15: image15,
  16: image16,
  17: image17,
  18: image18,
  20: image20,
  21: image21,
  22: image22,
  23: image23,
  24: image24,
  25: image25,
  26: image26,
  28: image28,
  29: image29,
  37: image37,
  44: image44,
  45: image45,
  46: image46,
  47: image47,
  48: image48,
  49: image49,
  50: image50,
  51: image51,
  52: image52,
  53: image53,
  54: image54,
  55: image55,
  56: image56,
  57: image57,
  58: image58,
  59: image59,
  60: image60,
  63: image63,
  65: image65,
  67: image67,
  69: image69,
  70: image70,
  71: image71,
  72: image72,
};

export default ImageMap;
