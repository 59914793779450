import React, { useRef, useEffect, useState } from 'react';
import ForceGraph from 'force-graph';
import { NodeObject, LinkObject } from 'force-graph';
import bilyaLogo from './bilyalogo.png';

const NODE_COLOURS = {
  bilya: '#000000',
  map_existing_networks: '#9CDADE',
  preserve_knowledge: '#BDDE9C',
  support_evolution: '#D3D3D3',
  foster_connections: '#848484',
  promote_collaboration: '#FF4773',
  centre_country: '#755B48',
  emphasise_engagement: '#443E3C',
  operate_transparency: '#FFCB47',
  universally_accessible: '#AB6D52',
  exist_sustainably: '#47B2FF',
};

type AboutNodeType = NodeObject & {
  id: string;
  name: string;
  neighbors?: AboutNodeType[];
  links?: LinkObject[];
};

const AboutGraph = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const graphRef = useRef<any>(null);
  const highlightNodes = new Set<string>();
  const highlightLinks = new Set<LinkObject>();
  const [hoveredNodeId, setHoveredNodeId] = useState<string | null>(null);

  const nodes: AboutNodeType[] = [
    { id: 'bilya', name: 'Bilya' },
    { id: 'map_existing_networks', name: 'Map existing networks' },
    {
      id: 'preserve_knowledge',
      name: 'Preserve and share knowledge and resources',
    },
    {
      id: 'support_evolution',
      name: 'Support and track the evolution of individual and collective practices',
    },
    {
      id: 'foster_connections',
      name: 'Foster connections, nurture relationships, and cultivate communities',
    },
    {
      id: 'promote_collaboration',
      name: 'Promote collaborative, transdisciplinary, intergenerational approaches',
    },
    { id: 'centre_country', name: 'Centre Country in climate action' },
    {
      id: 'emphasise_engagement',
      name: 'Emphasise real-world engagement and impact',
    },
    {
      id: 'operate_transparency',
      name: 'Operate with transparency and accountability, following cultural protocols',
    },
    { id: 'universally_accessible', name: 'Be universally accessible' },
    { id: 'exist_sustainably', name: 'Exist sustainably for 100 years' },
  ];

  const links = [
    { source: 'bilya', target: 'map_existing_networks' },
    { source: 'bilya', target: 'preserve_knowledge' },
    { source: 'bilya', target: 'support_evolution' },
    { source: 'bilya', target: 'foster_connections' },
    { source: 'bilya', target: 'promote_collaboration' },
    { source: 'bilya', target: 'centre_country' },
    { source: 'bilya', target: 'emphasise_engagement' },
    { source: 'bilya', target: 'operate_transparency' },
    { source: 'bilya', target: 'universally_accessible' },
    { source: 'bilya', target: 'exist_sustainably' },
  ];

  // Cross-link node objects
  links.forEach((link) => {
    const a = nodes.find((node) => node.id === link.source);
    const b = nodes.find((node) => node.id === link.target);
    if (a && b) {
      !a.neighbors && (a.neighbors = []);
      !b.neighbors && (b.neighbors = []);
      a.neighbors.push(b);
      b.neighbors.push(a);

      !a.links && (a.links = []);
      !b.links && (b.links = []);
      a.links.push(link);
      b.links.push(link);
    }
  });

  const NODE_R = 4;

  useEffect(() => {
    if (containerRef.current) {
      const graph = ForceGraph()(containerRef.current)
        .graphData({ nodes: nodes as AboutNodeType[], links })
        .nodeColor((node) => {
          return (
            NODE_COLOURS[node.id as keyof typeof NODE_COLOURS] || '#FFFFFF'
          ); // Use NODE_COLOURS for node colors, default to white
        })
        .nodeLabel(() => '') // Prevent any labels from appearing
        .nodeAutoColorBy('id') // Automatically color nodes based on their ID
        .onNodeClick((node) => {
          console.log('Node clicked:', node);
        })
        .onNodeHover((node) => {
          highlightNodes.clear();
          highlightLinks.clear();
          if (node) {
            highlightNodes.add(node.id as string);
            setHoveredNodeId(node.id as string); // Update hoveredNodeId
            (node as any).neighbors.forEach((neighbor: AboutNodeType) =>
              highlightNodes.add(neighbor.id)
            );
            (node as any).links.forEach((link: LinkObject) =>
              highlightLinks.add(link)
            );
          } else {
            setHoveredNodeId(null); // Reset hoveredNodeId
          }
        })
        .nodeCanvasObjectMode((node) =>
          highlightNodes.has((node as AboutNodeType).id) ? 'replace' : 'replace'
        )
        .nodeCanvasObject((node, ctx) => {
          if (node.id === 'bilya') {
            const img = new Image();
            img.src = bilyaLogo;
            if (node.x && node.y) {
              ctx.drawImage(
                img,
                node.x - NODE_R * 1.4,
                node.y - NODE_R * 1.4,
                NODE_R * 2.8,
                NODE_R * 2.8
              ); // Adjust size as needed
            }
          } else {
            // add ring just for highlighted nodes
            ctx.beginPath();
            if (node.x && node.y) {
              ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
              ctx.fillStyle = NODE_COLOURS[node.id as keyof typeof NODE_COLOURS]
                ? NODE_COLOURS[node.id as keyof typeof NODE_COLOURS] // 100% opacity
                : '#FFFFFF'; // 100% opacity for white
              ctx.fill();
            }
          }
        })
        .autoPauseRedraw(false) // keep redrawing after engine has stopped
        .linkWidth((link) => (highlightLinks.has(link) ? 5 : 1));

      graphRef.current = graph;
      graph.zoom(6);
      graph.centerAt(60, 5);
    }
  }, []);

  return (
    <div style={{ display: 'flex', width: '100%', height: '100vh' }}>
      <div style={{ width: '50%', padding: '20px 40px' }}>
        <h2 style={{ fontFamily: 'IBM Plex Serif, serif', fontWeight: 400 }}>
          Bilya is a relational map of people, projects, and organisations
          responding to the climate emergency through culture and the arts.
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 40px',
            gap: '15px',
            width: '500px',
          }}
        >
          {nodes
            .filter((node) => node.id !== 'bilya')
            .map((node) => (
              <div // Changed from span to div to push the entire block
                key={node.id}
                style={{
                  backgroundColor: NODE_COLOURS[
                    node.id as keyof typeof NODE_COLOURS
                  ]
                    ? `${NODE_COLOURS[node.id as keyof typeof NODE_COLOURS]}99` // 70% transparency
                    : '#FFFFFF99', // 70% transparency for white
                  borderRadius: '15px',
                  padding: '5px 10px',
                  border: '1px solid #000000',
                  fontFamily: 'IBM Plex Sans, sans-serif',
                  fontSize: '14px',
                  fontWeight: 400,
                  marginLeft: node.id === hoveredNodeId ? '10px' : '0', // Push to the right if hovered
                  display: 'flex', // Added to keep the text centered
                  alignItems: 'center', // Center the text vertically
                }}
              >
                {node.name}
              </div>
            ))}
        </div>
      </div>
      <div ref={containerRef} style={{ width: '50%', height: '100%' }} />
    </div>
  );
};

export default AboutGraph;
