import React from 'react';

const DefaultImage = ({ width = 920, height = 855, scale = 1 }) => (
  <svg
    width={width * scale}
    height={height * scale}
    viewBox="0 0 920 855"
    fill="none"
  >
    <rect width="920" height="855" fill="#D9D9D9" />
    <g style={{ mixBlendMode: 'overlay' }}>
      <path
        d="M459.955 433.198C409.062 362.047 358.252 290.828 286.202 290.828C207.683 290.828 144.03 354.481 144.03 433C144.03 511.519 207.683 575.171 286.202 575.171C358.496 575.171 409.252 504.218 459.955 433.198ZM459.955 433.198C510.753 504.218 561.633 575.171 633.798 575.171C712.317 575.171 775.97 511.519 775.97 433C775.97 354.481 712.317 290.828 633.798 290.828C561.499 290.828 510.753 362.047 459.955 433.198ZM665.749 433C665.749 546.632 573.632 638.75 460 638.75C346.368 638.75 254.251 546.632 254.251 433C254.251 319.368 346.368 227.251 460 227.251C573.632 227.251 665.749 319.368 665.749 433Z"
        stroke="white"
        strokeWidth="43"
      />
    </g>
    <g style={{ mixBlendMode: 'overlay' }}>
      <path
        d="M459.955 433.198C409.062 362.047 358.252 290.828 286.202 290.828C207.683 290.828 144.03 354.481 144.03 433C144.03 511.519 207.683 575.171 286.202 575.171C358.496 575.171 409.252 504.218 459.955 433.198ZM459.955 433.198C510.753 504.218 561.633 575.171 633.798 575.171C712.317 575.171 775.97 511.519 775.97 433C775.97 354.481 712.317 290.828 633.798 290.828C561.499 290.828 510.753 362.047 459.955 433.198ZM665.749 433C665.749 546.632 573.632 638.75 460 638.75C346.368 638.75 254.251 546.632 254.251 433C254.251 319.368 346.368 227.251 460 227.251C573.632 227.251 665.749 319.368 665.749 433Z"
        stroke="white"
        strokeOpacity="0.25"
        strokeWidth="43"
      />
    </g>
  </svg>
);

export default DefaultImage;
